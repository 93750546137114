import "./PatientRegister.css";
import '../../App.css';
import patientregisterimg from "../../media/login_img.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../StateProviders";

function PatientRegister() {
    let navigate = useNavigate();
    const [{ IsLogin, IsRegister }, dispatch] = useStateValue();

    const [options, setoptions] = useState([true, false, false, false]);
    const [name, setname] = useState("");
    const [Phnumber, setPhnumber] = useState("");
    const [Date, setDate] = useState("");
    const [Img, setImg] = useState("");
    const [gender, setgender] = useState("male");
    const [Weight, setWeight] = useState("");
    const [height, setheight] = useState("");
    const [profession, setprofession] = useState("");

    const [Address, setAddress] = useState("");
    const [City, setCity] = useState("");
    const [State, setState] = useState("");
    const [Country, setCountry] = useState("");
    const [OtherInfo, setOtherInfo] = useState("");
    const [file, setFile] = useState();
    const [Pincode, setPincode] = useState("");
    const [showImg, setshowImg] = useState();
    const [Activegender, setActivegender] = useState([false, false]);

    return (
        <>
            <div className="client_register_main_division">
                {/* <div className='client_register_first_main_division'>
                    <div className='client_register_img_division'>
                        <img className='client_register_img' src={patientregisterimg} />
                    </div>
                </div> */}

                <div className="client_register_second_third_merge_main_division">
                    <div className="client_register_second_main_division">
                        <div
                            className="client_register_registration_main_division"
                            onClick={() => {
                                if (options[0]) {
                                    setoptions([true, false, false, false]);
                                }
                            }}
                        >
                            <div className="client_register_registration_number_division">
                                <span
                                    className={
                                        options[0]
                                            ? "client_register_registration_number_title_active"
                                            : "client_register_registration_number_title"
                                    }
                                >
                                    1
                                </span>
                            </div>
                            <div className="client_register_registration_division">
                                <div className="client_register_registration_title">
                                    Registration
                                </div>
                                <div className="client_register_registration_sub_title">
                                    Enter Details for Register
                                </div>
                            </div>
                        </div>

                        <div
                            className="client_register_registration_main_division"
                            onClick={() => {
                                if (options[1]) setoptions([true, true, false, false]);
                            }}
                        >
                            <div className="client_register_registration_number_division">
                                <div
                                    className={
                                        options[1]
                                            ? "client_register_registration_number_title_active"
                                            : "client_register_registration_number_title"
                                    }
                                >
                                    2
                                </div>
                            </div>
                            <div className="client_register_registration_division">
                                <div className="client_register_registration_title">
                                    Profile Picture
                                </div>
                                <div className="client_register_registration_sub_title">
                                    Upload Profile picture
                                </div>
                            </div>
                        </div>

                        <div
                            className="client_register_registration_main_division"
                            onClick={() => {
                                if (options[2]) setoptions([true, true, true, false]);
                            }}
                        >
                            <div className="client_register_registration_number_division">
                                <div
                                    className={
                                        options[2]
                                            ? "client_register_registration_number_title_active"
                                            : "client_register_registration_number_title"
                                    }
                                >
                                    3
                                </div>
                            </div>
                            <div className="client_register_registration_division">
                                <div className="client_register_registration_title">
                                    Personal Details
                                </div>
                                <div className="client_register_registration_sub_title">
                                    Enter your Personal Details
                                </div>
                            </div>
                        </div>

                        <div
                            className="client_register_registration_main_division"
                            onClick={() => {
                                if (options[3]) {
                                    setoptions([true, true, true, true]);
                                }
                            }}
                        >
                            <div className="client_register_registration_number_division">
                                <div
                                    className={
                                        options[3]
                                            ? "client_register_registration_number_title_active"
                                            : "client_register_registration_number_title"
                                    }
                                >
                                    4
                                </div>
                            </div>
                            <div className="client_register_registration_division">
                                <div className="client_register_registration_title">
                                    Other Detail
                                </div>
                                <div className="client_register_registration_sub_title">
                                    More information
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* registration step 1 start */}
                    {options[0] && !options[1] ? (
                        <div className="client_register_third_main_division">
                            <div className="client_register_registration_details_main_division">
                                Whats your Number?
                                <span className="client_register_registration_star_icon">
                                    *
                                </span>
                            </div>
                            <div className="client_register_registration_details_sub_division">
                                We will only use it to advise you for any important changes.
                            </div>

                            <div class="client_register_email_field_division form-floating mt-3">
                                <input
                                    type="text"
                                    class="client_register_email_field form-control"
                                    id="floatingInput"
                                    value={name}
                                    onChange={(e) => {
                                        setname(e.target.value);
                                    }}
                                />
                                <label for="floatingInput">
                                    Name
                                    <span className="client_register_registration_star_icon">
                                        *
                                    </span>
                                </label>
                            </div>
                            <div class="client_register_email_field_division form-floating mt-3">
                                <input
                                    type="text"
                                    class="client_register_email_field form-control"
                                    id="floatingPassword"
                                    value={Phnumber}
                                    onChange={(e) => {
                                        setPhnumber(e.target.value);
                                    }}
                                />
                                <label for="floatingPassword">
                                    Mobile Number
                                    <span className="client_register_registration_star_icon">
                                        *
                                    </span>
                                </label>
                            </div>
                            <div className="client_register_date_feild_division">
                                <input
                                    type="date"
                                    class="client_register_email_field form-control"
                                    id="inputdateofbirth"
                                    value={Date}
                                    onChange={(e) => {
                                        setDate(e.target.value);
                                    }} />
                            </div>


                            <button
                                className="client_register_continue_btn"
                                onClick={() => {
                                    if (Phnumber !== "" && name !== "" && Date !== "") {
                                        setoptions([true, true, false, false]);
                                    } else {
                                        alert("Enter Name , Number , Date Of Birth");
                                    }
                                }}
                            >
                                Continue
                            </button>
                        </div>
                    ) : null}

                    {/* registration step 1 end */}

                    {/* upload img step 2 start */}
                    {options[1] && !options[2] ? (
                        <div className="client_register_third_main_division">
                            <div className="client_register_registration_details_main_division">
                                Upload Profile Picture
                            </div>
                            <div className="client_register_registration_details_sub_division">
                                Add a profile photo
                            </div>

                            <div className="client_register_upload_img_main_division">
                               {showImg ?
                                <img src={showImg} />
                                :
                                <div className="client_register_upload_img_division">
                                    <i class="fa-solid fa-camera"></i>
                                </div>}
                            </div>

                            <div className="client_register_upload_images_main_division">
                                {/* <input type="file" onChange={(e) => {

                                    setImg(e.target.files[0])

                                    setshowImg(URL.createObjectURL(e.target.files[0]));
                                }} />
                                <div className='client_register_upload_images_division'><i class="client_register_upload_images_icon_division fa-solid fa-upload"></i> Upload images</div> */}

                                <button
                                    type="button"
                                    className="clientregister_upload_photo_button btn"
                                >
                                    <i className="fa fa-upload profilesettings_upload_icon"></i>{" "}
                                    Upload Photo
                                    <input
                                        type="file"
                                        accept="image/gif, image/jpeg, image/png"
                                        onChange={(e) => {
                                            setImg(e.target.files[0]);

                                            setshowImg(URL.createObjectURL(e.target.files[0]));
                                        }}
                                    />
                                </button>
                            </div>
                            {/* <div className='client_register_upload_images_main_division'>
                            <div className='client_register_upload_images_division'><i class="client_register_upload_images_icon_division fa-solid fa-camera"></i> Take a photo </div>
                        </div> */}
                            <button
                                className="client_register_profile_continue_btn"
                                onClick={() => {
                                    setoptions([true, true, true, false]);
                                }}
                            >
                                Continue
                            </button>
                        </div>
                    ) : null}

                    {/* upload img step 2 end */}

                    {/* personal details step 3 start */}

                    {options[2] && !options[3] ? (
                        <div className="client_register_third_main_division">
                            <div className="client_register_registration_details_main_division">
                                What are your personal details?
                                <span className="client_register_registration_star_icon">
                                    *
                                </span>
                            </div>
                            <div className="client_register_registration_details_sub_division">
                                Please provide your personal information
                            </div>

                            <div className="client_register_personal_gender_main_division">
                                <button className={
                                    Activegender[0]
                                        ? "client_register_personal_male_btn_active"
                                        : "client_register_personal_male_btn"
                                }
                                    onClick={() => {
                                        setgender("male");
                                        setActivegender([true, false]);
                                    }}
                                >
                                    <i class="fa-solid fa-mars-stroke"></i> Male
                                </button>
                                <button
                                    className={Activegender[1] ? "client_register_personal_female_btn_active" : "client_register_personal_female_btn"
                                    }
                                    onClick={() => {
                                        setgender("female");
                                        setActivegender([false, true]);
                                    }}
                                >
                                    <i class="fa-solid fa-mars-stroke-up"></i> Female
                                </button>
                            </div>

                            <div class="client_register_email_field_division_age form-floating mt-3">
                                <input
                                    type="mail"
                                    class="client_register_email_field form-control"
                                    id="floatingInput"
                                    placeholder="password"
                                    value={profession}
                                    onChange={(e) => {
                                        setprofession(e.target.value);
                                    }}
                                />
                                <label for="floatingInput">
                                    Profession</label>
                            </div>
                            {/* <select
                                class="client_register_blood_type_deopdown_division form-select"
                                aria-label="Default select example"
                                value={Bloodgrp}
                                onChange={(e) => {
                                    setBloodgrp(e.target.value);
                                }}
                            >
                                <option selected >
                                    Blood Type
                                </option>
                                <option>A+</option>
                                <option>A-</option>
                                <option>B+</option>
                                <option>B-</option>
                                <option>O+</option>
                                <option>O-</option>
                                <option>AB+</option>
                                <option>AB-</option>
                            </select> */}

                            {/* <div className="client_register_weight_dropdown_division  align-center">
                                <div class="client_register_email_field_division_one form-floating mt-3">
                                    <input
                                        type="text"
                                        class="client_register_weight_field form-control"
                                        id="floatingInput"
                                        placeholder="password"
                                        value={Weight}
                                        onChange={(e) => {
                                            setWeight(e.target.value);
                                        }}
                                    />
                                    <label for="floatingInput">
                                        {" "}
                                        Your Weight In KG
                                        <span className="client_register_registration_star_icon">
                                            (optional)
                                        </span>
                                    </label>
                                </div>
                                

                                <div class="client_register_email_field_division_1 form-floating  mt-3 ">
                                    <input
                                        type="text"
                                        class="client_register_weight_field form-control"
                                        id="floatingInput"
                                        placeholder="password"
                                        value={height}
                                        onChange={(e) => {
                                            setheight(e.target.value);
                                        }}
                                    />
                                    <label for="floatingInput">
                                        Your Height In CM
                                        <span className="client_register_registration_star_icon">
                                            (optional)
                                        </span>
                                    </label>
                                </div>
                            </div> */}

                            {/* <div className='client_register_heart_fropdown'>
                                <div class="  client_register_email_field_division_one form-floating mt-3 ">
                                    <input type="text"
                                        class="client_register_email_field form-control"
                                        id="floatingPassword"
                                        placeholder="password"
                                        value={Heartrate}
                                        onChange={(e) => {
                                            setHeartrate(e.target.value)

                                        }}
                                    />
                                    <label for="floatingPassword">Heart Rate<span className='client_register_registration_star_icon'>(optional)</span></label>
                                </div>
                                <div class=" client_register_email_field_division_1 form-floating mt-3">
                                    <input type="text" class="client_register_email_field form-control" id="floatingPassword"  placeholder="password"  value={BP}
                                        onChange={(e) => {
                                            setBP(e.target.value)

                                        }}
                                    />
                                    <label for="floatingPassword">Blood Pressure<span className='client_register_registration_star_icon'>(optional)</span></label>
                                </div>
                            </div> */}

                            {/* <div className=" client_register_gluecose_div ">
                                <div class="client_register_email_field_division_one form-floating mt-3">
                                    <input
                                        type="text"
                                        class="client_register_email_field form-control"
                                        placeholder="password"
                                        id="floatingPassword"
                                        onChange={(e) => {
                                            setGluclose(e.target.value);
                                        }}
                                    />
                                    <label for="floatingPassword">
                                        Glucose Level
                                        <span className="client_register_registration_star_icon">
                                            (optional)
                                        </span>
                                    </label>
                                </div>

                                <div class="client_register_email_field_division_1 form-floating mt-3">
                                    <input
                                        type="text"
                                        class="client_register_email_field form-control"
                                        placeholder="password"
                                        id="floatingPassword"
                                        value={Allergies}
                                        onChange={(e) => {
                                            setAllergies(e.target.value);
                                        }}
                                    />
                                    <label for="floatingPassword">
                                        Allergies
                                        <span className="client_register_registration_star_icon">
                                            (optional)
                                        </span>
                                    </label>
                                </div>
                            </div> */}

                            <button
                                className="client_register_profile_continue_btn"
                                onClick={() => {
                                    if (profession !== "") {
                                        setoptions([true, true, true, true]);
                                    } else {
                                        alert("Enter Gender and Profession");
                                    }
                                }}
                            >
                                Continue
                            </button>
                        </div>
                    ) : null}

                    {/* personal details step 3 end */}

                    {/* other details step 4 start */}

                    {options[3] ? (
                        <div className="client_register_third_main_division">
                            <div className="client_register_registration_details_main_division">
                                Other Details
                                <span className="client_register_registration_star_icon">
                                    *
                                </span>
                            </div>
                            <div className="client_register_registration_details_sub_division">
                                Please fill the details below
                            </div>

                            <div class="client_register_email_field_division form-floating mt-3">
                                <input
                                    type="text"
                                    class="client_register_email_field form-control"
                                    id="floatingInput"
                                    value={Address}
                                    onChange={(e) => {
                                        setAddress(e.target.value);
                                    }}
                                />
                                <label for="floatingInput">Enter Address</label>
                            </div>

                            <div className=" client_register_gluecose_div ">
                                <div class="client_register_email_field_division_one form-floating mt-3">
                                    <input type="text" class="client_register_email_field form-control" id="floatingInput" placeholder="name@example.com"
                                        onChange={(e) => {
                                            setCity(e.target.value)

                                        }}
                                    />
                                    <label for="floatingInput">Enter City</label>
                                </div>

                                <div class="client_register_email_field_division_1 form-floating mt-3">
                                    <input type="text" class="client_register_email_field form-control" id="floatingInput" placeholder="name@example.com"
                                        onChange={(e) => {
                                            setState(e.target.value)

                                        }}
                                    />
                                    <label for="floatingInput">Enter State</label>
                                </div>
                            </div>
                            <div className=" client_register_gluecose_div ">
                                <div class="client_register_email_field_division_one form-floating mt-3">
                                    <input type="text" class="client_register_email_field form-control" id="floatingInput" placeholder="name@example.com"
                                        onChange={(e) => {
                                            setCountry(e.target.value)

                                        }}
                                    />
                                    <label for="floatingInput">Enter Country</label>
                                </div>

                                <div class=" client_register_email_field_division_1 form-floating mt-3">
                                    <input type="text" class="client_register_email_field form-control" id="floatingInput" placeholder="name@example.com"
                                        onChange={(e) => {
                                            setPincode(e.target.value)

                                        }}
                                    />
                                    <label for="floatingTextarea2">Pincode</label>
                                </div>
                            </div>
                            {/* <div class=" client_register_blood_type_deopdown_division form-floating">
                            <textarea class=" client_register_blood_type_deopdown_division_text_area form-control" placeholder="Leave a comment here" id="floatingTextarea2"
                                onChange={(e) => {
                                    setOtherInfo(e.target.value)

                                }}
                            />
                            <label for="floatingTextarea2">Other Information</label>
                        </div> */}

                            <button
                                className="client_register_profile_continue_btn"
                                onClick={() => {
                                    console.log(IsRegister);
                                    if (options[3]) {
                                        if (Address !== "") {
                                            var Url =
                                                window.API_PREFIX + "patient/add_registration_data";

                                            var uploadData = new FormData();
                                            uploadData.append(
                                                "Token",
                                                localStorage.getItem("DToken")
                                            );
                                            uploadData.append("Name", name);
                                            uploadData.append("Pic", Img);
                                            uploadData.append("Gender", gender);
                                            uploadData.append("Weight", Weight);
                                            uploadData.append("Height", height);
                                            uploadData.append("profession", profession);
                                            uploadData.append("Birthdate", Date);
                                         
                                            uploadData.append("Address", Address);
                                            uploadData.append("City", City);
                                            uploadData.append("State", State);
                                            uploadData.append("Country", Country);
                                            uploadData.append("Pincode", Pincode);
                                            uploadData.append("OtherInformation", OtherInfo);
                                            uploadData.append("Mobile", Phnumber);

                                            fetch(Url, {
                                                method: "POST",
                                                // headers: {
                                                //     'Content-type': 'application/json',
                                                // },
                                                body: uploadData,
                                            })
                                                .then((resp) => resp.json())
                                                .then((data) => {
                                                    console.log(data);
                                                    if (data["status"] === "1") {
                                                        alert("Yor Details Successfully Registered");
                                                        dispatch({ type: "Register" });
                                                        dispatch({ type: "Login" });
                                                        navigate("/dashboard");
                                                    }
                                                });
                                        } else {
                                            alert("Enter Address");
                                        }
                                    }
                                }}
                            >
                                Continue
                            </button>
                        </div>
                    ) : null}

                    {/* other details step 4 end */}
                </div>
            </div>
        </>
    );
}

export default PatientRegister;
