import './ContactUs.css';
import '../../App.css';
import { useNavigate } from 'react-router-dom';
function ContactUs() {
    let navigate = useNavigate();

    return (
        <>


            <div className='about_main_div'>
                <div className='container text-center col-md-5'>
                    <h1 className='contact_title'>Contact US</h1>
                    <div className='contact_para'>
                        <i>We are a young and creative company and we offer you fresh business ideas.</i>
                    </div>

                </div>

            </div>

            <div className='container contact_main_div'>
                <div className=' row'>
                    <div className='col-md-6 contact_sub_div'>
                        <span>CONTACT</span>
                        <h1>
                            How can we help your business to grow?
                        </h1>
                        <div>
                            <i>We bring the breathe of our experience and industry knowledge to help you succeed</i>
                        </div>
                    </div>
                    <div className='col-md-6 contact_tag_div'>
                        <div className='contact_tag_main'>
                            <input type="email" class="form-control me-3  " id="exampleFormControlInput1" placeholder="Name" />
                            <input type="email" class="form-control  " id="exampleFormControlInput1" placeholder="Mail" />
                        </div>
                        <input type="text" pattern="\d*" inputmode="numeric" class="form-control mt-4" id="exampleFormControlInput1" placeholder="Phone Number" />
                        <textarea class="form-control mt-5" id="exampleFormControlTextarea1" rows="5" placeholder='Your Message'></textarea>
                        <button id="submit" class="form-control mt-5 submit_btn" >SEND A MESSAGE</button>
                    </div>

                </div>
                <div className='row mt-5'>
                    <div className=' contact_us col-12 col-md-6'>
                        <h3><span className=' call_us'>Call Us</span></h3>
                        <h3><span className='contact_number'>+91 8160082609</span></h3>
                    </div>
                    <div className=' visit_us col-12 col-md-3'>
                        <h3><span>Visit Us</span></h3>
                        <h3>
                            <span className='visit_us'>
                                <strong>Working Days</strong><br />
                                <span className='color_us'>Monday - Friday</span>
                            </span>
                        </h3>
                    </div>
                    <div className=' follow_us col-12 col-md-3'>
                        <h4>
                            <span>Follow Us</span>
                        </h4>
                        <div className='social_icon'>
                            <a className='facebook_icon' href='##'>
                                <i class="fa-brands fa-facebook-f"></i>
                            </a>
                            <a className='facebook_icon' href='33'>
                                <svg style={{ height: '30px', width: '30px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#ffffff" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>
                            </a>
                            <a className='facebook_icon' href='https://www.instagram.com/vinnyrituagarwal/'>
                                <i class="fa-brands fa-instagram"></i>
                            </a>
                            <a className='facebook_icon' href=''>
                                <i class="fa-brands fa-linkedin-in"></i>
                            </a>

                        </div>
                    </div>
                </div>

            </div>



        </>
    )
}

export default ContactUs;