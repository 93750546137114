import './Patient_Booking.css';
import '../../App.css';
import Carousel from "react-elastic-carousel";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './OrderSummary.css';
import '../Patient/Sidebar.css';
import PatientSidebar from './PatientSidebar';


function Patient_Booking() {
    let navigate = useNavigate();



    const [AllDate, setAllDate] = useState([])

    const [activeDate, setActiveDate] = useState(null)
    const [activeDay, setActiveDay] = useState(null)
    const [TimePopup, setTimePopup] = useState(false)
    const [BookingPage, setBookingPage] = useState(false)
    const [disablebutton, setdisablebutton] = useState(false)
    const [Price, setPrice] = useState([])

    const [AppPurpose, setAppPurpose] = useState("")
    const [SelectedSlot, setSelectedSlot] = useState(null)
    const [TimeSlot, setTimeSlot] = useState([])
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 4, itemsToScroll: 2, pagination: false },
        { width: 768, itemsToShow: 5 },
        { width: 1150, itemsToShow: 5, itemsToScroll: 2 },
        { width: 1440, itemsToShow: 6 },
        { width: 1750, itemsToShow: 6 },
    ];
    function showAdvanceSlot(SelectedDate) {


        var Url = window.API_PREFIX + 'timing/showslot_advance'
        console.log(SelectedDate)
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    'Token': localStorage.getItem('DToken'),
                    Date: SelectedDate

                }
            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {
                setTimeSlot([...data["TotalSlot"]])

            }
        })

    }

    useEffect(() => {



        var Url = window.API_PREFIX + 'timing/show_slot'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    'Token': localStorage.getItem('DToken'),

                }
            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {
                setTimeSlot([...data["TotalSlot"]])
                setAllDate([...data["date_List"]])
                setActiveDate(data["date_List"][0]['Date'])
                setActiveDay(data["date_List"][0]['Day'])
                setPrice(data["Price"])






            }
        }
        )




    }, [])

    const [DocData, setDocData] = useState({});
    useEffect(() => {
        var Url = window.API_PREFIX + 'admin/Singledoctordetail'
        fetch(Url, {
            method: 'GET',

        }).then(resp => resp.json()).then(data => {
            // console.log("sv-->",data)
            if (data['status'] === "1") {

                setDocData({ ...data['data'] })

            }
        })



    }, [])



    // const [appPurpose, setAppPurpose] = useState('');
    // const [bookingPage, setBookingPage] = useState(false);

    const handleTextareaChange = (e) => {
        setAppPurpose(e.target.value);
    };

    const handleNextButtonClick = () => {
        if (AppPurpose.trim() !== '') {
            setBookingPage(true);
        } else {
            alert('Please fill in the textarea before proceeding.');
        }
    };



    return (!BookingPage ?
        <>
            {/* breadcrumbs-start */}
            {/* Navtag-start */}
            <nav className='client_booking_breadcrumb_main_division'>
                <div className='client_booking_breadcrumb_division'>
                    <p className='client_booking_breadcrumb_title'

                        onClick={() => { navigate("/homepage") }}
                    >home</p>
                    <p className='client_booking_breadcrumb_title1'>/</p>
                    <p className='client_booking_breadcrumb_title2'
                        onClick={() => { navigate("/dashboard") }}
                    >Book Appoinment</p>
                </div>
                <div>
                    <h1 className='client_booking_breadcrumb_title_main'>Book Appoinment</h1>
                </div>
            </nav>
            {/* Navtag-end */}
            {/* breadcrumbs-end */}
            {/* maintag-start */}
            <main className='slidebar_and_component'>
                <PatientSidebar />
                {/* <Patientsidebarresponsive /> */}

                <div className="container purpose_of_visit_division_container">
                    <div className='purpose_of_visit_main_division'>
                        {/* <label for="exampleInputEmail1" className='purpose_of_visit_label'>purpose of visit</label> */}
                        <label for="exampleFormControlInput1" class="form-label purpose_of_visit_name">Purpose of visit
                            <div className='requires_feild'>(* required) </div> </label>

                        <textarea class="form-control purpose_of_visit_textarea" id="exampleFormControlTextarea1" rows="3" placeholder="Meeting, Consultancy, etc.."
                            // onChange={(e) => {
                            //     setAppPurpose(e.target.value)
                            // }}

                            value={AppPurpose}
                            onChange={handleTextareaChange}
                        ></textarea>

                        {/* <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Purpose of visit"/> */}
                    </div>

                    <div class="d-flex justify-content-center mt-3">
                        <div>
                            <i class="fa-solid fa-square me-2 indication_1"></i>
                            Availble
                        </div>
                        <div class="ms-3">
                            <i class="fa-solid fa-square me-2 indication_2"></i>
                            Not Availble
                        </div>
                        <div class="ms-3">
                            <i class="fa-solid fa-square me-2 indication_3"></i>
                            Selected
                        </div>
                    </div>

                    {/* 
                    <div className='client_booking_date_day'>
                        <p className='client_booking_date'>{activeDate}</p>
                        <p className='client_booking_day'>{activeDay}</p>
                    </div> */}
                    <div className='client_booking_main_diviison'>

                        <div className='client_booking_diviison'>
                            <div className='client_booking_week_division'>
                                <Carousel breakPoints={breakPoints}>
                                    {AllDate.map((eachDay, DayIndex) => {
                                        return (
                                            <div
                                                onClick={() => {
                                                    setActiveDate(eachDay['Date'])
                                                    setActiveDay(eachDay['Day'])
                                                    showAdvanceSlot(eachDay['Date'])



                                                }
                                                }
                                                className={activeDate == eachDay['Date'] ? 'client_booking_week_name_active' : 'client_booking_week_name'}>
                                                <div
                                                // className='client_booking_week_name'

                                                >
                                                    {eachDay['Day']}

                                                </div>
                                                <div className={eachDay['Date'] ? 'client_booking_day_name_active' : 'client_booking_day_name'}>
                                                    {eachDay['Date']}
                                                </div>
                                            </div>
                                        )

                                    })}

                                </Carousel>
                            </div>

                            <div className='client_booking_time_slot_table_division'>
                                {TimeSlot.map((eachTimeSlot, TimeSlotindex) => {

                                    return (
                                        <div className={!eachTimeSlot['Flag'] ? 'client_booking_time_slots_active' : eachTimeSlot['Slot'] === SelectedSlot ? "client_booking_time_slots_active_selected" : "client_booking_time_slots"}
                                            onClick={() => {
                                                if (eachTimeSlot['Flag'] === 1) {
                                                    setSelectedSlot(eachTimeSlot['Slot'])

                                                }
                                            }}
                                        >{eachTimeSlot['Slot']}</div>
                                    )
                                })}

                            </div>


                        </div>
                        <div className='client_booking_proceedpay_button_division'>
                            <button type="button" class="btn client_booking_proceedpay_button"
                                // onClick={() => {
                                //     setBookingPage(true)
                                // }}

                                onClick={handleNextButtonClick}
                                disabled={AppPurpose.trim() === ''}
                            >Next</button>
                        </div>
                    </div>
                </div>
            </main>
            {/* maintag-end */}
        </> :

        <>
            <div className='OrderSummry_back_button_division'
                onClick={() => {
                    setBookingPage(false)

                }}>
                <button className='btn OrderSummry_back_button'
                ><i class="fa-solid fa-arrow-left back_icon"></i>Back</button></div>
            <div className="OrderSummry_main_division">

                <div className="OrderSummry_division">
                    <p className='OrderSummry_summary_name'>Booking summary</p>
                    <div className='OrderSummry_image_doc_division'>
                        <div className='OrderSummry_image_division'>
                            <img className="OrderSummry_image" src={DocData['Image']}></img>
                        </div>
                        <div>
                            <div className='OrderSummry_doctor_name'> {DocData['Name']}</div>
                            <div className='OrderSummry_doctor_name'> {DocData['Address']}</div>

                        </div>

                    </div>

                    <div className='ordersummary_date_time_fee_main_division'>
                        <div className='ordersummary_date_time_fee_division'>
                            <div className='ordersummary_leftside'> date</div>
                            <div className='ordersummary_rightside'>{activeDate}</div>
                        </div>
                        <div className='ordersummary_date_time_fee_division'>
                            <div className='ordersummary_leftside'> time</div>
                            <div className='ordersummary_rightside'>{SelectedSlot}</div>
                        </div>
                        <div className='ordersummary_date_time_fee_division'>
                            <div className='ordersummary_leftside'> consulting fee</div>
                            <div className='ordersummary_rightside'>{Price}</div>
                        </div>
                        <div className='ordersummary_date_total_division'>
                            <div className='ordersummary_total'>total</div>
                            <div className='ordersummary_rightside'>{Price}</div>
                        </div>
                    </div>

                    <div className='client_booking_proceedpay_button_division'>
                        <button type="button" class="btn client_booking_proceedpay_button" disabled={disablebutton}
                            onClick={() => {
                                console.log('API triggered timer disable')
                                setdisablebutton(true)

                                const getData = setTimeout(() => {
                                    if (!disablebutton) {


                                        var Url = window.API_PREFIX + 'booking/booking'
                                        fetch(Url, {
                                            method: 'POST',
                                            headers: {
                                                'Content-type': 'application/json',
                                            },
                                            body: JSON.stringify(
                                                {
                                                    'Token': localStorage.getItem('DToken'),
                                                    'Slot': SelectedSlot,
                                                    'Date': activeDate,
                                                    'Purpose': AppPurpose,
                                                    'Price': Price

                                                }
                                            ),
                                        }).then(resp => resp.json()).then(data => {
                                            console.log(data)
                                            if (data['status'] === "1") {

                                                navigate('/dashboard')

                                            }

                                        })
                                    }
                                }, 3000)

                                return () => clearTimeout(getData)
                            }}
                        >Request Appoinment</button>
                    </div>



                </div>

            </div>
        </>
    )
}
export default Patient_Booking;