import './Doctor_Dashboard.css';
import '../../App.css';
import { useEffect, useState } from 'react';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import rightdoctor from '../homepageimg/rightdoctor.jpg'
import DoctorSidebar from './DoctorSidebar'
import './Sidebar.css'
import { useStateValue } from '../StateProviders';
import { Button, notification } from "antd";


const openNotification = () => {
    notification.open({
        style: { padding: '10px', borderRadius: '10px', width: '280px' },
        duration: 0,
        placement: "bottomLeft",
        message:
            <div><div className='coach_dashboard_notification_main_div'>
                <div>
                    <img alt='' className='coach_dashboard_notification_patient_image' src={rightdoctor}></img>
                </div>
                <div>
                    <div className='coach_dashboard_notification_patient_name'>Peril Patel</div>
                    <div className='coach_dashboard_notification_booking_time'>09:00 AM - 09:30 AM</div>
                    <div className='coach_dashboard_notification_date'>24 APR 2023</div>
                </div>


            </div>
                <div>
                    <button className='btn coach_dashboard_tabel_accept_btn_mobile mt-3' >
                        {/* <i class="fa-solid fa-check"></i> */}
                        Accept
                    </button>
                    <button className='btn coach_dashboard_tabel_cancel_btn_mobile mt-3'>
                        {/* <i class="fa-solid fa-xmark"></i> */}
                        Reject
                    </button>
                </div>
            </div>,


    });

};


function Doctor_Dashboard() {

    const [{ Loading }, dispatch] = useStateValue();
    const [currentPage, setCurrentPage] = useState(0);
    const handlePageChange = (page) => {
        setCurrentPage(page - 1);
        console.log('Current page:', page);
    };



    const [entriesPerPage, setEntriesPerPage] = useState(10);

    const handleEntriesPerPageChange = (current, size) => {
        setEntriesPerPage(size);
        console.log('Entries per page:', size);
    };
    function Pending_Payment(Paymentindex) {
        console.log(AppointmentData[Paymentindex])
        var Url = window.API_PREFIX + 'admin/Paybydoctor'
        dispatch({
            type: 'StartLoad'
        })
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    Token: localStorage.getItem('DToken'),
                    Id: AppointmentData[Paymentindex]['BookingId'],
                    PatientId: AppointmentData[Paymentindex]['PatientId']


                }
            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            dispatch({
                type: 'StopLoad'
            })
            if (data['status'] === "1") {
                var tmp = AppointmentData
                tmp[Paymentindex]['BookingStatus'] = 2
                setAppointmentData([...tmp])
            }
        })

    }


    function AcceptRequest(Requestindex) {
        var Url = window.API_PREFIX + 'booking/approve_booking'
        console.log(AppointmentData[Requestindex])
        dispatch({
            type: 'StartLoad'
        })
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    Token: localStorage.getItem('DToken'),
                    Id: AppointmentData[Requestindex]['BookingId']


                }
            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            dispatch({
                type: 'StopLoad'
            })
            if (data['status'] === "1") {
                var tmp = AppointmentData
                tmp[Requestindex]['BookingStatus'] = 1
                setAppointmentData([...tmp])
            }
        })


    }


    function RejectRequest(Requestindex) {

        dispatch({
            type: 'StartLoad'
        })
        var Url = window.API_PREFIX + 'booking/reject_booking'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    Token: localStorage.getItem('DToken'),
                    Id: AppointmentData[Requestindex]['BookingId'],




                }
            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            dispatch({
                type: 'StopLoad'
            })
            if (data['status'] === "1") {
                var tmp = AppointmentData
                tmp[Requestindex]['BookingStatus'] = -1
                setAppointmentData([...tmp])
            }
        })

    }



    function DeleteRequest(Requestindex) {

        dispatch({
            type: 'StartLoad'
        })
        var Url = window.API_PREFIX + 'admin/delete_booking'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    Token: localStorage.getItem('DToken'),
                    Id: AppointmentData[Requestindex]['BookingId'],




                }
            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            dispatch({
                type: 'StopLoad'
            })
            if (data['status'] === "1") {
                var tmp = AppointmentData
                tmp[Requestindex]['BookingStatus'] = -1
                setAppointmentData([...tmp])
            }
        })

    }
    // function AcceptRequest(Requestindex) {
    //     var tmp = AppointmentData
    //     tmp[Requestindex]['BookingStatus'] = 1
    //     setAppointmentData([...tmp])
    // }

    // function RejectRequest(Requestindex) {
    //     var tmp = AppointmentData
    //     tmp[Requestindex]['BookingStatus'] = -1
    //     setAppointmentData([...tmp])
    // }



    const columns = [
        {
            title: 'Client Name',

            dataIndex: 'PatientName',
            key: 'name',
            //   render: (text) => <a>{text}</a>,
            render: (text, record) => <>
                <div className='coach_dashboard_table_image_name_division'>
                    <img className='coach_dashboard_table_image' src={record['ProfilePic']} style={{ height: '2.5rem', width: '2.5rem' }} />
                    <div className='coach_dashboard_table_name_id_division'>
                        <div className='coach_dashboard_table_patient_name'>{text}</div>
                        <div className='coach_dashboard_table_patient_id'>#pt{record['PatientId']}</div>
                    </div>
                </div>
            </>

        },
        {
            title: 'Appt Date',
            dataIndex: 'Date',
            key: 'date',
            render: (text, record) => <>
                <div className='coach_dashboard_time_date_div'>
                    <div className='coach_dashboard_table_appt_date'>{text}</div>
                    <div className='coach_dashboard_table_appt_time'>{record['Time']}</div>
                </div>
            </>
        },
        {
            title: 'Purpose',
            dataIndex: 'Purpose',
            key: 'purpose',
            render: (text, record) => <>
                <div className='coach_dashboard_table_appt_date'>{text}</div>

            </>
        },
        {
            title: 'Type',
            dataIndex: 'Patienthistory',
            key: 'type',
            render: (text, record) => <>
                <div className='coach_dashboard_table_appt_date'>{text ? 'Old Patient' : 'New Patient'}</div>

            </>
        },
        {
            title: 'Paid Amount',
            dataIndex: 'Amount',
            key: 'paidamount',
            //         render: (text,record) => <>
            //         <div className='coach_dashboard_table_appt_date'>{text}</div>

            // </>
        },
        {

            title: 'Option',
            key: 'tags',
            dataIndex: 'BookingStatus',
            render: (text, record, index) => (

                <>
                    {text === 0 ? <div className='coach_dashboard_tabel_view_accept_cancel'>
                        <div><button className='btn coach_dashboard_tabel_accept_btn'
                            onClick={() => {
                                var INDEX = currentPage * entriesPerPage + index
                                AcceptRequest(INDEX)
                            }}
                        >Accept</button></div>
                        <div><button className='btn coach_dashboard_tabel_cancel_btn'

                            onClick={() => {
                                var INDEX = currentPage * entriesPerPage + index

                                RejectRequest(INDEX)
                            }}
                        >Reject</button></div>
                    </div> : text === 1 ?
                        <div className='coach_dashboard_tabel_view_accept_cancel'>
                            <div><button className='btn coach_dashboard_tabel_accept_btn' disabled>Accepted</button></div>
                        </div>
                        : text === 2 ?
                            <div className='coach_dashboard_tabel_view_accept_cancel'>
                                <div><button className='btn coach_dashboard_tabel_accept_btn' disabled>Accepted</button></div>
                            </div>
                            :
                            <div className='coach_dashboard_tabel_view_accept_cancel'>
                                <div><button className='btn coach_dashboard_tabel_cancel_btn'
                                    disabled
                                // onClick={()=>{
                                //         dispatch({
                                //             type:'StartLoad'
                                //         })
                                // }}
                                >Rejected</button></div>
                            </div>
                    }
                    {/* <div
                        onClick={() => {
                            var INDEX = currentPage * entriesPerPage + index

                            DeleteRequest(INDEX)
                        }}
                    >
                        <i class="delete_icon fa-regular fa-trash-can"></i>
                    </div> */}
                </>
            ),
        },
        {

            title: 'Payment Status',
            key: 'tags',
            dataIndex: 'Ispaid',
            render: (text, record, index) => (
                <>
                    <div className='coach_dashboard_tabel_view_accept_cancel'>
                        {record['BookingStatus'] === 1 ? <div><button className='btn coach_dashboard_tabel_view_btn'
                            onClick={() => {
                                var INDEX = currentPage * entriesPerPage + index

                                Pending_Payment(INDEX)
                            }}
                        >
                            {/* <i class="fa-regular fa-eye me-1"></i> */}
                            Pending Payment</button></div>
                            :
                            record['BookingStatus'] === 2 ?
                                <div><button className='btn coach_dashboard_tabel_accept_btn' disabled>
                                    {/* <i class="fa-regular fa-eye me-1"></i> */}
                                    Paid</button></div> :
                                record['BookingStatus'] === 0 ?
                                    <div><button className='btn coach_dashboard_tabel_view_btn' disabled>
                                        {/* <i class="fa-regular fa-eye me-1"></i> */}
                                        Pending Request</button></div> :
                                    <div><button className='btn coach_dashboard_tabel_cancel_btn' disabled>
                                        {/* <i class="fa-regular fa-eye me-1"></i> */}
                                        N/A</button></div>

                        }
                        {/* <div><button className='btn coach_dashboard_tabel_cancel_btn'><i class="fa-regular fa-eye me-1"></i>Cancel</button></div> */}
                    </div>
                </>
            ),
        },
    ];

    const [AppointmentData, setAppointmentData] = useState([])
    const [AppointmentOption, setAppointmentOption] = useState([true, false, false])

    useEffect(() => {
        // dispatch({
        //     type:'StartLoad'
        // })
        var Url = window.API_PREFIX + 'admin/upcoming_booking'
        fetch(Url, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
            },
            // body: JSON.stringify({'Token':localStorage.getItem('DToken')}),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            // dispatch({
            //     type:'StopLoad'
            // })
            if (data['status'] === "1") {
                setAppointmentData([...data["Booking"]])
            }
        })
    }, [])





    // responsive table data start






    // responsive table data end

    return (
        <>
            {/* breadcrumbs-start */}
            <nav className='coach_dashboard_breadcrumb_main_division' >
                <div className='coach_dashboard_breadcrumb_division'>
                    <p className='coach_dashboard_breadcrumb_title'>home</p>
                    <p className='coach_dashboard_breadcrumb_title1'>/</p>
                    <p className='coach_dashboard_breadcrumb_title2'>dashboard</p>
                </div>
                <div>
                    <h1 className='coach_dashboard_breadcrumb_title_main'>dashboard</h1>
                </div>
            </nav>
            {/* breadcrumbs-end */}

            <div className="container-fuild">
                <div className='slidebar_and_component'>
                    <DoctorSidebar />
                    <div className='component_division'>
                        <div className='coach_dashboard_main_diviison'>

                            <div className='coach_dashboard_patient_appointment_table_main_division'>
                                <p className='coach_dashboard_patientappoinment_name'>Client Appoinments</p>
                                <div className='coach_dashboard_upcoming_today_btn_divsion'>
                                    <div className='coach_dashboard_today_btn_divsion'>
                                        <button type="button" class={AppointmentOption[0] ? "btn coach_dashboard_upcoming_btn_active" : 'btn coach_dashboard_upcoming_btn'}
                                            onClick={() => {

                                                var Url = window.API_PREFIX + 'admin/upcoming_booking'
                                                fetch(Url, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-type': 'application/json',
                                                    },
                                                    // body: JSON.stringify({'Token':localStorage.getItem('DToken')}),
                                                }).then(resp => resp.json()).then(data => {
                                                    console.log(data)
                                                    if (data['status'] === "1") {
                                                        setAppointmentOption([true, false, false])

                                                        setAppointmentData([...data["Booking"]])
                                                    }
                                                })
                                            }}
                                        >Upcoming</button>
                                    </div>
                                    <div className='coach_dashboard_today_btn_divsion'>
                                        <button type="button" class={AppointmentOption[1] ? "btn coach_dashboard_upcoming_btn_active" : 'btn coach_dashboard_upcoming_btn'}
                                            onClick={() => {
                                                var Url = window.API_PREFIX + 'admin/Today_booking'
                                                fetch(Url, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-type': 'application/json',
                                                    },
                                                    // body: JSON.stringify({'Token':localStorage.getItem('DToken')}),
                                                }).then(resp => resp.json()).then(data => {
                                                    console.log(data)
                                                    if (data['status'] === "1") {
                                                        setAppointmentOption([false, true, false])
                                                        setAppointmentData([...data["Booking"]])
                                                    }
                                                })

                                            }}
                                        >Today</button>
                                    </div>
                                    <div className='coach_dashboard_today_btn_divsion'>
                                        <button type="button" class={AppointmentOption[2] ? "btn coach_dashboard_upcoming_btn_active" : 'btn coach_dashboard_upcoming_btn'}
                                            onClick={() => {


                                                var Url = window.API_PREFIX + 'admin/Previous_booking'
                                                fetch(Url, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-type': 'application/json',
                                                    },
                                                    // body: JSON.stringify({'Token':localStorage.getItem('DToken')}),
                                                }).then(resp => resp.json()).then(data => {
                                                    console.log(data)
                                                    if (data['status'] === "1") {
                                                        setAppointmentOption([false, false, true])

                                                        setAppointmentData([...data["Booking"]])
                                                    }
                                                })
                                            }}>Previous</button>
                                    </div>
                                </div>
                                <div className='coach_dashboard_patient_appointment_table_division'>
                                    <Table columns={columns} dataSource={AppointmentData}
                                        pagination={{
                                            current: currentPage + 1,
                                            onChange: handlePageChange,
                                            pageSize: entriesPerPage,
                                            onShowSizeChange: handleEntriesPerPageChange,
                                        }}
                                    />

                                    {/* <Button type="primary" onClick={openNotification}>
                                        Open the notification box
                                    </Button> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Doctor_Dashboard;