import { useEffect, useState } from 'react';
import React from 'react';
import headerlogo from '../media/headermainlogo.png';
import { useStateValue } from './StateProviders';
import './Header.css';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import vralogo from '../media/about/vralogo.png';
import { Slant as Hamburger } from "hamburger-react";
import vra from '../media/about/vralogo.png';



function Header() {


    const [anchorEl, setAnchorEl] = useState(null);
    const oopen = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    const [profile, setProfile] = useState(true)
    const [{ IsLogin, IsRegister }, dispatch] = useStateValue();
    const [userdata, setuserdata] = useState({});
    const [activeMenu, setActiveMenu] = useState([false, false, false, false])

    let navigate = useNavigate();
    useEffect(() => {
        console.log(">>>Header  IsLogin")
        // dispatch({ type: 'Register' })

        // if (!IsLogin) {
        var Url = window.API_PREFIX + 'patient/check_user'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    Token: localStorage.getItem('DToken'),
                }
            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {

                dispatch({ type: 'Login' })



                setuserdata({ ...data })
            }
            else {
                // navigate('/login')
            }
            console.log(IsLogin)
            console.log(IsRegister)
        })
        // }
    }, [IsLogin])

    useEffect(() => {
        console.log(">>>Header  Register")
        // dispatch({ type: 'Register' })

        // if (!IsLogin) {
        var Url = window.API_PREFIX + 'patient/check_user'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    Token: localStorage.getItem('DToken'),
                }
            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {

                if (!data['IsRegister']) {
                    dispatch({ type: 'NotRegister' })


                }
                else {
                    dispatch({ type: 'Register' })
                }



                setuserdata({ ...data })
            }
            else {
                // navigate('/login')
            }

            console.log(IsLogin)
            console.log(IsRegister)
        })
        // }
    }, [IsRegister])



    const [isFullScrVisible, setFullScrVisible] = useState(false);
    const fullScrStyle = {
        left: isFullScrVisible ? 0 : "",
    };

    const handleMenuClick = () => {
        setFullScrVisible(!isFullScrVisible);
    };

    const [isOpenn, setOpenn] = useState(false);


    const [options, setoptions] = useState([true, false]);



    return (

        // Header-start
        <header className="container-fuild ">

            <div className="" id="full-scr" style={fullScrStyle}>
                <div className='d-flex justify-content-between align-items-center' style={{ borderBottom: '1px solid grey' }}>
                    <img src={vra} style={{ width: '50%' }} ></img>
                    <div onClick={handleMenuClick}>
                        <Hamburger
                            toggled={isOpenn}
                            toggle={setOpenn}
                            size={30}
                            color='black'
                        />
                    </div>
                </div>
                <div className='mt-2 sidebar_div_two'>
                    <div onClick={() => {
                        navigate('/')
                        setFullScrVisible(false)
                    }}>Home</div>

                    <div onClick={() => {
                        navigate('/aboutus')
                        setFullScrVisible(false)
                    }}>About</div>

                    <div onClick={() => {
                        navigate('/booking')
                        setFullScrVisible(false)
                    }}>Booking</div>

                    <div onClick={() => {
                        navigate('/ContactUs')
                        setFullScrVisible(false)
                    }}>Contact US</div>
                </div>
            </div>





            <div className=" header_main_division">
                <div className="header_logo_main_division">
                    <img className="header_logo_division" src={vralogo} alt="123" onClick={() => { navigate('/') }} />
                </div>

                <div className='btn_bar' onClick={handleMenuClick}>
                    <Hamburger
                        toggled={isOpenn}
                        toggle={setOpenn}
                        size={30}
                        color='black'
                    />
                </div>



                <div className='header_center_main_division'>
                    <div className={activeMenu[0] ? 'header_center_division_active' : 'header_center_division'}
                        onClick={() => {
                            setActiveMenu([true, false, false, false])
                            navigate('/')
                        }}>
                        home
                    </div>

                    <div className={activeMenu[2] ? 'header_center_division_active' : 'header_center_division'}
                        onClick={() => {
                            setActiveMenu([false, false, true, false])
                            navigate('/aboutus')
                        }}>
                        about
                    </div>
                    <div className={activeMenu[1] ? 'header_center_division_active' : 'header_center_division'}
                        onClick={() => {
                            setActiveMenu([false, true, false, false])
                            navigate('/booking')
                        }}>
                        booking
                    </div>
                    <div className={activeMenu[3] ? 'header_center_division_active' : 'header_center_division'}
                        onClick={() => {
                            setActiveMenu([false, false, false, true])
                            navigate('/ContactUs')
                        }}>
                        contact us
                    </div>
                </div>
                {IsRegister && IsLogin ?
                    <div className="header_contact_profile_main_division">
                        <div className='header_contact_main_division'>
                            {/* <div><i class="fa-regular fa-building header_contact_icon"></i></div> */}
                            <div className='header_contact_division me-2'>
                                <p className='header_contact_title'>Contact</p>
                                <p className='header_contact_number'><p><a href="tel:+91 8160082609">+91 816008260</a></p></p>
                            </div>

                            <div className='me-3'>
                                <Tooltip title="Notifications">
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={oopen ? 'long-menu' : undefined}
                                        aria-expanded={oopen ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                    >
                                        <Badge badgeContent={2} color="primary">
                                            <i class="fa-solid fa-bell "></i>
                                        </Badge>

                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    className='notification_main_div'
                                    disableScrollLock={true}
                                    id="long-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={oopen}
                                    onClose={handleClose}
                                    PaperProps={{
                                        style: {
                                            width: '30ch',
                                            height: '300px',
                                            marginRight: '10px',
                                            padding: '5px',
                                            borderRadius: '10px',
                                        },
                                    }}
                                >

                                    <div className='doctor_header_notification_title_main'>
                                        <div className='doctor_header_notification_title'>
                                            <h6 className='doctor_header_notification_title_1 col'>Notifications</h6>
                                            <span className='doctor_header_notification_title_2 badge col-auto'>4 New</span>
                                        </div>
                                    </div>

                                    <div className='doctor_header_notification_title_main_1'>
                                        <ul class="nav nav-tabs ">
                                            <li class="nav-item ">
                                                <a class="nav-link doctor_header_recent"
                                                    onClick={() => {
                                                        setoptions([true, false]);
                                                    }} data-bs-toggle="tab" >
                                                    Recents
                                                </a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a class="nav-link doctor_header_previous" onClick={() => {
                                                    setoptions([false, true]);
                                                }} data-bs-toggle="tab">
                                                    Previous
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                    {
                                        options[0] ?
                                            <div>
                                                <MenuItem>
                                                    <div>- Your Appoinment Is successfully<br></br> Booked</div>
                                                </MenuItem>
                                                <MenuItem >
                                                    <div>- Your Appoinment Is Rejected,<br></br> Call Doctor For Further Details.</div>
                                                </MenuItem>
                                            </div> 
                                            : null
                                    }

                                    {
                                        options[1] ?
                                            <div>
                                               efefef
                                            </div> 
                                            : null
                                    }

                                </Menu>
                            </div>

                        </div>

                        <div onClick={(() => { setProfile(!profile) }
                        )} className='header_profile_main_division me-3'>
                            <div className='header_profile_image_division'>
                                <img className=' header_profile_image' src={userdata['Img']}></img>
                            </div>
                            <div><i class={profile ? "fa-solid fa-chevron-down" : "fa-solid fa-chevron-up header_profile_arrow_up_icon"}></i></div>
                            {!profile ? <div className='header_profile_division'>
                                <div className='header_profile_division_header'>
                                    <div className='header_profile_header_profile_image_division'><img className='header_profile_header_profile_image' src={userdata['Img']}></img></div>
                                    <div>
                                        <p className='header_profile_header_name'>{userdata['Name']}</p>
                                        <p className='header_profile_header_postname'>patient:{userdata['displayId']}</p>
                                    </div>
                                </div>
                                <div className='header_profile_list'
                                    onClick={() => {
                                        navigate('/dashboard')
                                    }}
                                ><i class="fa-solid fa-table-columns me-2"></i>  Dashboard</div>
                                <div className='header_profile_list'
                                    onClick={() => {
                                        navigate('/profilesetting')
                                    }}
                                ><i class="fa-solid fa-gears me-2"></i> Profile Settings</div>
                                <div className='header_profile_list'
                                    onClick={() => {
                                        navigate('/changepassword')
                                    }}
                                ><i class="fa-solid fa-lock me-2"></i> Change Password</div>
                                <div className='header_profile_list'
                                    onClick={() => {
                                        localStorage.clear()
                                        dispatch({
                                            type: "Logout"
                                        })
                                        dispatch({ type: 'NotRegister' })

                                        navigate("")
                                    }}
                                ><i class="fa-solid fa-right-from-bracket me-2"></i> Log Out</div>
                            </div> : null}
                        </div>
                    </div>

                    :

                    <div className='header_login_signup_btn_division'>
                        <button className='btn header_login_signup_btn'
                            onClick={() => {
                                navigate('/login')
                                localStorage.clear()
                                dispatch({ type: "Logout" })
                                dispatch({ type: 'NotRegister' })

                            }}
                        >Login / Signup</button>
                    </div>
                }
            </div>
        </header>
        // Header-end

    )
}
export default Header;