import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { useEffect } from 'react';
import { useStateValue } from './StateProviders';
import { useNavigate } from 'react-router-dom';
import DoccureLogin from './Patient/DoctorLogin'
import PatientRegister from './Patient/PatientRegister';
import Homepage from './Homepage/Homepage';
import Headernew from './Headernew';


export default function HomeLayout() {
  let navigate = useNavigate()
  const [{ IsLogin, IsRegister }, dispatch] = useStateValue();
  useEffect(() => {

    console.log(IsRegister)
    if (localStorage.getItem('DToken') !== null && localStorage.getItem("LoginDetails") === 'User') {
      dispatch({ type: 'Login' });
    }
    else {
      dispatch({ type: 'Logout' });
    }


  }, [])
  return (<>
    <Header />
    {/* <Headernew /> */}

    {
      window.location.pathname === '/' ||
        window.location.pathname === '/ContactUs' ||
        window.location.pathname === '/aboutus'


        ?
        <Outlet />
        :
        !IsLogin ?
          <DoccureLogin /> :
          !IsRegister ?
            <PatientRegister /> :

            <Outlet />
    }

    <Footer />
  </>
  )
} 