import React from 'react';
import '../../App.css';
import 'swiper/css';
import "swiper/css/pagination";
import './Homepage.css';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper';
import softskill from '../../media/homepageimages/skill-soft.png'
import grooming from '../../media/homepageimages/Grooming.png'
import transformation from '../../media/homepageimages/transformational.png'
import posture from '../../media/homepageimages/posture.png'
import process from '../../media/homepageimages/hr-process.png'
import business from '../../media/homepageimages/buisness.png'

function Homepage() {

  let navigate = useNavigate();

  const clients = [
    {
      testinomial: 'Thanks to your exceptional training, I have developed an unwavering confidence in myself. I was able to confidently address alarge crowd and assert my authority in my position, all thanks to your invaluable guidance. Your training has been nothing short of amazing, and I am immensely grateful for it.',
      name: ' Mrs.Khot ',
      proffession: '(Bank Manager Pune)',
    },
    {
      testinomial: 'Thanks to your exceptional training, I have developed an unwavering confidence in myself. I was able to confidently address alarge crowd and assert my authority in my position, all thanks to your invaluable guidance. Your training has been nothing short of amazing, and I am immensely grateful for it.',
      name: ' Mrs.Khot ',
      proffession: '(Bank Manager Pune)',
    },
    {
      testinomial: 'Thanks to your exceptional training, I have developed an unwavering confidence in myself. I was able to confidently address alarge crowd and assert my authority in my position, all thanks to your invaluable guidance. Your training has been nothing short of amazing, and I am immensely grateful for it.',
      name: ' Mrs.Khot ',
      proffession: '(Bank Manager Pune)',
    },
    {
      testinomial: 'Thanks to your exceptional training, I have developed an unwavering confidence in myself. I was able to confidently address alarge crowd and assert my authority in my position, all thanks to your invaluable guidance. Your training has been nothing short of amazing, and I am immensely grateful for it.',
      name: ' Mrs.Khot ',
      proffession: '(Bank Manager Pune)',
    },
  ]
  return (
    <>

      <div className='coach_background_image_wrapper'>
        <div className='coach_first_main_div container'>
          <div className='coach_homepage_wrapper_1 row'>
            <div className='coach_homepage_column'>
              <h1 className='headline_tag'>
                <span>
                  LEADING YOU
                  <br />
                  THROUGH YOUR
                  <br />
                  <strong>GROWTH JOURNEY</strong>
                </span>
              </h1>
              <div class="headline_work col-12 col-md-7"><i>Approach life and business with confidence
                through a psychological and analytical perspective.</i></div>
            </div>
          </div>
        </div>
      </div>


      <div className='coach_second_main_div container'>
        <div className='coach_second_first_div'>
          <div class="coach_superheadline">WHAT WE DO</div>
          <div class="coach_headline_content"><span>We make it easy</span></div>
        </div>
        <div className='coach_second_sec_div '>
          <div className='coach_first_column row gap-3'>
            <div class="card_1 col-12 col-md-4" >
              <img src={softskill} className='soft_skill'></img>
              <div><h3>Soft skill training</h3></div>
            </div>
            <div class="card_1 col-12 col-md-4">
              <img src={grooming} className='soft_skill'></img>
              <div ><h3>sales training</h3></div>
            </div>
            <div class="card_1 col-12 col-md-4">
              <img src={transformation} className='soft_skill'></img>
              <div ><h3>communication skill and personality development training</h3></div>
            </div>
          </div>
        </div>
        <div className='coach_second_sec_div_1 '>
          <div className='coach_first_column row gap-3'>
            <div class="card_1 col-12 col-md-3">
              <img src={posture} className='soft_skill'></img>
              <div ><h3>Life coaching</h3></div>
            </div>
            <div class="card_1 col-12 col-md-3">
              <img src={process} className='soft_skill'></img>
              <div class="card-body">
                <div class="card-title"><h3>Business coaching</h3></div>
              </div>
            </div>
            <div class="card_1 col-12 col-md-3">
              <img src={business} className='soft_skill'></img>
              <div><h3>Behavioural training </h3></div>
            </div>
          </div>
        </div>
      </div>

      <div className='coach_fifth_main_div container'>
        <div className='our_program'>OUR PROGRAMS</div>
        <div className='four_programs row'>
          <div className='col-12 col-md-3'>
            <div className='program_title'>
              Ladder to<br />
              Success
            </div>
            <div className='program_para'>
              Efficiency is what this
              program is all about
              focus on all key
              interpersonal and
              intrapersonal skills,
              developing a key
              understanding of critical
              and creative thinking
              and knowing one's true
              strength and weakness.
            </div>
          </div>
          <div className='col-12 col-md-3'>
            <div className='program_title'>
              The Sales<br />
              Mastery
            </div>
            <div className='program_para'>
              A Program Designed to
              help the sales team build
              the skills to Analyse the
              market and close the
              deal while maintain the
              company culture and
              goodwill presentation
              high and developing a
              keen understanding of
              consumer behavior
            </div>
          </div>
          <div className='col-12 col-md-3'>
            <div className='program_title'>
              Tapping
              towards<br />
              Triumph
            </div>
            <div className='program_para'>
              This program is
              designed to help
              companies and
              individual graph out the
              targets and help in the
              decision-making process
              in order to achieve those
              targets with consistency,
              confidence and
              concentration..
            </div>
          </div>
          <div className='col-12 col-md-3'>
            <div className='program_title'>
              The
              Profund<br />
              Lady
            </div>
            <div className='program_para'>
              A unique program
              designed for women to
              empower them and
              groom them to become
              the best version of
              themselves to know their
              true protentional and
              achieve greater height
              intellectually and
              spiritually.
            </div>
          </div>
        </div>
      </div>

      <div className='coach_sixth_main_div'>
        <div className='coach_sixth_sub_div '>
          <h1 className='contact_text'>Don't wait any longer to invest in your Personal & Professional Development !!!</h1>
          <button type="button" className="btn contactus_btn " onClick={(() => (
            navigate('/ContactUs')
          ))}>Conatct Us</button>

        </div>
      </div>


      <div className='coach_fourth_main_div container'>
        <div className='coach_first_div '>
          <p class='back_text'>800+</p>
          <p class='front_text'>800+</p>
          <p class='sub_title'>Attendees</p>
        </div>
        <div className='coach_first_div '>
          <p class='back_text'>25+</p>
          <p class='front_text'>25+</p>
          <p class='sub_title'>Companies</p>
        </div>
        <div className='coach_first_div '>
          <p class='back_text'>18+</p>
          <p class='front_text'>18+</p>
          <p class='sub_title'>Individuals</p>
        </div>
        <div className='coach_first_div '>
          <p class='back_text'>50+</p>
          <p class='front_text'>50+</p>
          <p class='sub_title'>Seminars</p>
        </div>
      </div>

      <div className='coach_third_main_div container'>
        <div className='coach_text_left container'>CLIENT TESTIMONIAL</div>
        <Swiper

          modules={[Autoplay]}
          slidesPerView={1}
          spaceBetween={30}
          autoplay={true}
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {
            clients.map((data) => (

              <SwiperSlide>
                <div className='client_testimonial'>
                  <div className='stars_left'>
                    <i class="fa-solid fa-star "></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                  </div>
                  <h4 className='testinomial_1'>{data.testinomial}</h4>
                  <h4 className='testinomial_1'>{data.name}</h4>
                  <h4 className='testinomial_1'>{data.proffession}</h4>
                </div> </SwiperSlide>
            ))
          }
        </Swiper>
      </div>


      {/* <div className='coach_third_main_div container'>

        <div className='coach_left_div row'>
          <div className='stars_left'>
            <i class="fa-solid fa-star "></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <div>
              <h4 className='testinomial_1'>Thanks to your exceptional training, I have
                developed an unwavering confidence in
                myself. I was able to confidently address a
                large crowd and assert my authority in my
                position, all thanks to your invaluable
                guidance. Your training has been nothing
                short of amazing, and I am immensely
                grateful for it.
                Mrs.Khot (Bank Manager Pune)</h4>
            </div>
          </div>
          <div className='coach_text_left'>
            Client <br /> Testimonial
          </div>
        </div>

        <div className='coach_right_div'>
          <div className='quote_right'>
            <i class="fa-solid fa-quote-right"></i>
          </div>
          <div className='stars_right'>
            <i class="fa-solid fa-star "></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <div className='text_right'>
              <h4 className='testinomial_1'>Hello mam,<br />
                I wanted to share that we have successfully
                secured ----- project. Kalyani led the conversation
                and addressed all the key points that were
                discussed in the sales pitch. This success is largely
                due to the training you provided. We plan to
                continue providing rigorous sales training to new
                joiners to maintain this success.<br />
                Thank you for your support!<br />
                Pasay Invento</h4>
            </div>
          </div>
        </div>

      </div> */}
    </>
  )
}
export default Homepage;