import './Doctor_Dashboard.css';
import '../../App.css';
import { useEffect, useState } from 'react';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import rightdoctor from '../homepageimg/rightdoctor.jpg'
import DoctorSidebar from './DoctorSidebar'
import './Sidebar.css'
import { useStateValue } from '../StateProviders';
import Doctorsidebarresponsive from './Doctorsidebarresponsive';


function DoctorAppointmentByDate() {

    const [, dispatch] = useStateValue();
    function Pending_Payment(Paymentindex) {
        console.log(AppointmentData[Paymentindex])
        dispatch({ type: 'StartLoad' })
        var Url = window.API_PREFIX + 'admin/Paybydoctor'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    Token: localStorage.getItem('DToken'),
                    Id: AppointmentData[Paymentindex]['BookingId'],
                    PatientId: AppointmentData[Paymentindex]['PatientId']


                }
            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            dispatch({ type: 'StopLoad' })

            if (data['status'] === "1") {
                var tmp = AppointmentData
                tmp[Paymentindex]['BookingStatus'] = 2
                setAppointmentData([...tmp])
            }
        })

    }


    function AcceptRequest(Requestindex) {
        var Url = window.API_PREFIX + 'booking/approve_booking'
        console.log(AppointmentData[Requestindex])
        dispatch({ type: 'StartLoad' })

        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    Token: localStorage.getItem('DToken'),
                    Id: AppointmentData[Requestindex]['BookingId']


                }
            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            dispatch({ type: 'StopLoad' })

            if (data['status'] === "1") {
                var tmp = AppointmentData
                tmp[Requestindex]['BookingStatus'] = 1
                setAppointmentData([...tmp])
            }
        })


    }


    function RejectRequest(Requestindex) {


        var Url = window.API_PREFIX + 'booking/reject_booking'
        dispatch({ type: 'StartLoad' })

        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    Token: localStorage.getItem('DToken'),
                    Id: AppointmentData[Requestindex]['BookingId'],




                }
            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            dispatch({ type: 'StopLoad' })

            if (data['status'] === "1") {
                var tmp = AppointmentData
                tmp[Requestindex]['BookingStatus'] = -1
                setAppointmentData([...tmp])
            }
        })

    }

    // function AcceptRequest(Requestindex) {
    //     var tmp = AppointmentData
    //     tmp[Requestindex]['BookingStatus'] = 1
    //     setAppointmentData([...tmp])
    // }

    // function RejectRequest(Requestindex) {
    //     var tmp = AppointmentData
    //     tmp[Requestindex]['BookingStatus'] = -1
    //     setAppointmentData([...tmp])
    // }



    const columns = [
        {
            title: 'Client Name',

            dataIndex: 'PatientName',
            key: 'name',
            //   render: (text) => <a>{text}</a>,
            render: (text, record) => <>
                <div className='coach_dashboard_table_image_name_division'>
                    <div className='coach_dashboard_table_image_division'>
                        <img className='coach_dashboard_table_image' src={record['ProfilePic']}
                            height="50px" width="50px" /></div>
                    <div className='coach_dashboard_table_name_id_division'>
                        <div className='coach_dashboard_table_patient_name'>{text}</div>
                        <div className='coach_dashboard_table_patient_id'>#pt{record['PatientId']}</div>
                    </div>
                </div>
            </>

        },
        {
            title: 'Appt Date',
            dataIndex: 'Date',
            key: 'date',
            render: (text, record) => <>
                <div className='coach_dashboard_time_date_div'>
                    <div className='coach_dashboard_table_appt_date'>{text}</div>
                    <div className='coach_dashboard_table_appt_time'>{record['Time']}</div>
                </div>
            </>
        },
        {
            title: 'Purpose',
            dataIndex: 'Purpose',
            key: 'purpose',
            render: (text, record) => <>
                <div className='coach_dashboard_table_appt_date'>{text}</div>

            </>
        },
        {
            title: 'Type',
            dataIndex: 'Patienthistory',
            key: 'type',
            render: (text, record) => <>
                <div className='coach_dashboard_table_appt_date'>{text ? 'Old Patient' : 'New Patient'}</div>

            </>
        },
        {
            title: 'Paid Amount',
            dataIndex: 'Amount',
            key: 'paidamount',
            //         render: (text,record) => <>
            //         <div className='coach_dashboard_table_appt_date'>{text}</div>

            // </>
        },
        {

            title: 'Option',
            key: 'tags',
            dataIndex: 'BookingStatus',
            render: (text, record, index) => (
                <>
                    {text === 0 ? <div className='coach_dashboard_tabel_view_accept_cancel'>
                        <div><button className='btn coach_dashboard_tabel_accept_btn'
                            onClick={() => {
                                AcceptRequest(index)
                            }}
                        >Accept</button></div>
                        <div><button className='btn coach_dashboard_tabel_cancel_btn'

                            onClick={() => {
                                RejectRequest(index)
                            }}
                        >Reject</button></div>
                    </div> : text === 1 ?
                        <div className='coach_dashboard_tabel_view_accept_cancel'>
                            <div><button className='btn coach_dashboard_tabel_accept_btn' disabled>Accepted</button></div>
                        </div>
                        : text === 2 ?
                            <div className='coach_dashboard_tabel_view_accept_cancel'>
                                <div><button className='btn coach_dashboard_tabel_accept_btn' disabled>Accepted</button></div>
                            </div>
                            :
                            <div className='coach_dashboard_tabel_view_accept_cancel'>
                                <div><button className='btn coach_dashboard_tabel_cancel_btn' disabled>Rejected</button></div>
                            </div>
                    }
                </>
            ),
        },
        {

            title: 'Payment Status',
            key: 'tags',
            dataIndex: 'Ispaid',
            render: (text, record, index) => (
                <>
                    <div className='coach_dashboard_tabel_view_accept_cancel'>
                        {record['BookingStatus'] === 1 ? <div><button className='btn coach_dashboard_tabel_view_btn'
                            onClick={() => {
                                Pending_Payment(index)
                            }}
                        >
                            {/* <i class="fa-regular fa-eye me-1"></i> */}
                            Pending Payment</button></div>
                            :
                            record['BookingStatus'] === 2 ?
                                <div><button className='btn coach_dashboard_tabel_accept_btn' disabled>
                                    {/* <i class="fa-regular fa-eye me-1"></i> */}
                                    Paid</button></div> :
                                record['BookingStatus'] === 0 ?
                                    <div><button className='btn coach_dashboard_tabel_view_btn' disabled>
                                        {/* <i class="fa-regular fa-eye me-1"></i> */}
                                        Pending Request</button></div> :
                                    <div><button className='btn coach_dashboard_tabel_cancel_btn' disabled>
                                        {/* <i class="fa-regular fa-eye me-1"></i> */}
                                        N/A</button></div>

                        }
                        {/* <div><button className='btn coach_dashboard_tabel_cancel_btn'><i class="fa-regular fa-eye me-1"></i>Cancel</button></div> */}
                    </div>
                </>
            ),
        },
    ];

    const [AppointmentData, setAppointmentData] = useState([])
    const [NonSearchAppointmentData, setNonSearchAppointmentData] = useState([])
    const [FilterDate, setFilterDate] = useState([])
    const [AppointmentOption, setAppointmentOption] = useState([true, false, false])

    useEffect(() => {

        var Url = window.API_PREFIX + 'admin/All_booking'
        fetch(Url, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
            },
            // body: JSON.stringify({'Token':localStorage.getItem('DToken')}),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {
                setAppointmentData([...data["Booking"]])
                setNonSearchAppointmentData([...data["Booking"]])
            }
        })
    }, [])



    return (
        <>
            {/* breadcrumbs-start */}
            <nav className='coach_dashboard_breadcrumb_main_division' >
                <div className='coach_dashboard_breadcrumb_division'>
                    <p className='coach_dashboard_breadcrumb_title'>home</p>
                    <p className='coach_dashboard_breadcrumb_title1'>/</p>
                    <p className='coach_dashboard_breadcrumb_title2'>Appoinment by date</p>
                </div>
                <div>
                    <h1 className='coach_dashboard_breadcrumb_title_main'>Appoinment by date</h1>
                </div>
            </nav>
            {/* breadcrumbs-end */}

            <div className="container-fuild">
                <div className='slidebar_and_component'>
                    <DoctorSidebar />
                    <div className='component_division'>
                        <div className='coach_dashboard_main_diviison'>
                            <span className='coach_dashboard_patientappoinment_name'>Appoinment By Date</span>
                            <div className='coach_dashboard_patientappoinment_date_division'>
                                <input type="date" className='coach_dashboard_patientappoinment_celender' id="birthday" name="birthday"
                                    onChange={(e) => {
                                        const date = new Date(e.target.value);

                                        const formattedDate = date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
                                        console.log(formattedDate)
                                        setFilterDate(formattedDate)
                                    }}
                                />
                                <button className='btn coach_dashboard_patientappoinment_date_btn'
                                    onClick={
                                        () => {

                                            console.log("FilterDate", FilterDate)
                                            setAppointmentData([...NonSearchAppointmentData.filter(item => item.Date === FilterDate)]);

                                            if (FilterDate === "" || FilterDate === 'Invalid Date') {
                                                setAppointmentData([...NonSearchAppointmentData])

                                            }
                                        }
                                    }
                                >Apply</button>
                            </div>
                        </div>
                        <div className='coach_dashboard_patient_appointment_table_main_division'>
                            <div className='coach_dashboard_patient_appointment_table_division'>
                                <Table columns={columns} dataSource={AppointmentData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
     
        </>
    )
}
export default DoctorAppointmentByDate;