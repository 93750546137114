import React from "react";
import '../../App.css';
import "./PatientChangePassword.css";
import { useState } from "react";

import PatientSidebar from "./PatientSidebar";
export default function PatientChangePassword() {
  const [oldpassword, setoldpassword] = useState("");
  const [newpassword, setnewpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");

  const [Inputtype, setInputtype] = useState("password");
  const [Inputtypen, setInputtypen] = useState("password");
  const [Inputtypeco, setInputtypeco] = useState("password");
  const [ButtonName, setButtonName] = useState(<i class="fa-solid fa-eye"></i>);
  const [ButtonNamen, setButtonNamen] = useState(<i class="fa-solid fa-eye"></i>);
  const [ButtonNameco, setButtonNameco] = useState(<i class="fa-solid fa-eye"></i>);
  const savechangesbutton = () => {
    if (newpassword === confirmpassword) {
      var Url = window.API_PREFIX + "patient/change_password";

      fetch(Url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          Token: localStorage.getItem("DToken"),
          Old_Password: oldpassword,
          New_Password: newpassword,
        }),
      })
        .then((resp) => resp.json())
        .then((data) => {
          console.log(data);
          if (data["status"] === "1") {
            alert("Password Successfull Changed");
          } else {
            alert(data["message"]);
          }
        });
    } else {
      alert("Password Didnt Matched");
    }
  };

  return (

    <>
      {/* breadcrumbs-start */}
      {/* Navtag-start */}
      <nav className="client_change_password_breadcrumb_main_division">
        <div className="client_change_password_breadcrumb_division">
          <p className="client_change_password_breadcrumb_title">home</p>
          <p className="client_change_password_breadcrumb_title1">/</p>
          <p className="client_change_password_breadcrumb_title2">
            Change Password
          </p>
        </div>
        <div>
          <h1 className="client_change_password_breadcrumb_title_main">
            Change Password
          </h1>
        </div>
      </nav>
      {/* Navtag-end */}
      {/* breadcrumbs-end */}
      {/* Maintag-start */}
      <main className="slidebar_and_component">
        <PatientSidebar />
        {/* <Patientsidebarresponsive /> */}
        <div className="component_division">
          <div className="client_change_passwords_main_diviison">
            <div className="client_change_password_section">
              <div className="client_change_password_section_old_password_section">
                <p className="client_change_password_section_old_password_title">
                  Old Password
                </p>
                <div className="client_change_password_old_password_division">
                  <input
                    class=" client_change_password_section_input_tag1 form-control form-control-lg"
                    type={Inputtype}
                    placeholder=""
                    aria-label=".form-control-lg example"
                    onChange={(e) => {
                      setoldpassword(e.target.value);
                    }}
                  ></input>
                  <div className="client_change_password_old_password_eye">
                    <i
                      className=" eye_icon"
                      onClick={(e) => {
                        if (Inputtype == "password") {
                          setInputtype("text");
                          setButtonName(
                            <i class="fa-solid fa-eye-slash"></i>
                          );
                        } else {
                          setInputtype("password");
                          setButtonName(<i class="fa-solid fa-eye"></i>);
                        }

                        e.preventDefault();
                      }}
                    >
                      {ButtonName}
                    </i>
                  </div>
                </div>
              </div>

              <div className="client_change_password_section_new_password_section">
                <p className="client_change_password_section_new_password_title">
                  New Password
                </p>
                <div className="client_change_password_old_password_division">
                  <input
                    class=" client_change_password_section_input_tag1 form-control form-control-lg"
                    type={Inputtypen}
                    placeholder=""
                    aria-label=".form-control-lg example"
                    onChange={(e) => {
                      setnewpassword(e.target.value);
                    }}
                  ></input>
                  <div className="client_change_password_old_password_eye">
                    <i
                      className=" eye_icon"
                      onClick={(e) => {
                        if (Inputtypen == "password") {
                          setInputtypen("text");
                          setButtonNamen(
                            <i class="fa-solid fa-eye-slash"></i>
                          );
                        } else {
                          setInputtypen("password");
                          setButtonNamen(<i class="fa-solid fa-eye"></i>);
                        }

                        e.preventDefault();
                      }}
                    >
                      {ButtonNamen}
                    </i>
                  </div>
                </div>
              </div>

              <div className="client_change_password_section_confirm_password_section">
                <p className="client_change_password_section_confirm_password_title">
                  Confirm Password
                </p>
                <div className="client_change_password_old_password_division">
                  <input
                    class=" client_change_password_section_input_tag1 form-control form-control-lg"
                    type={Inputtypeco}
                    placeholder=""
                    aria-label=".form-control-lg example"
                    onChange={(e) => {
                      setconfirmpassword(e.target.value);

                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        savechangesbutton()
                      }
                    }}
                  ></input>
                  <div className="client_change_password_old_password_eye">
                    <i
                      className=" eye_icon"
                      onClick={(e) => {
                        if (Inputtypeco == "password") {
                          setInputtypeco("text");
                          setButtonNameco(
                            <i class="fa-solid fa-eye-slash"></i>
                          );
                        } else {
                          setInputtypeco("password");
                          setButtonNameco(<i class="fa-solid fa-eye"></i>);
                        }

                        e.preventDefault();
                      }}
                    >
                      {ButtonNameco}
                    </i>
                  </div>
                </div>
              </div>

              {/* <div className='client_change_password_section_confirm_password_section'>
                                        <p className='client_change_password_section_confirm_password_title'>Confirm Password</p>
                                          
                                                <Input.Password
                                                size='large'
                                                className='client_change_password_section_input_tag'/>
                                           
                                        </div> */}

              <div className="client_change_password_section_save_button_section">
                <button
                  type="button"
                  class=" client_change_password_section_save_button btn"
                  onClick={savechangesbutton}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* Maintag-end */}

    </>

  );
}
