import './PatientDashboard.css';
import '../../App.css';
import 'antd/dist/antd.css';
import { Table } from 'antd';
import '../Patient/Sidebar.css'
import React from 'react';
import { useEffect, useState } from 'react';
import PatientSidebar from './PatientSidebar';
import Popup from 'reactjs-popup';
import "swiper/css";
import "swiper/css/pagination";
import { useStateValue } from '../StateProviders';
import { useNavigate } from 'react-router-dom';





// prescriptions table start

const Prescribecolumns = [
  {
    title: ' Name',
    dataIndex: 'Name',
    key: 'name',
  },
  {
    title: 'Date',
    dataIndex: 'Date',
    key: 'date',

  },


  {
    title: 'Attachment',
    dataIndex: 'Attachment',
    key: 'Attachment',
    render: (text) => (
      <>
        {/* <button className='client_dashboard_table_print_btn me-2'><i class="fa-solid fa-print"></i> Print</button> */}
        <a href={text} target="_blank"> <button className='client_dashboard_table_view_btn'><i class="fa-regular fa-eye"></i> View</button></a>
      </>
    ),
  },


];


// prescriptions table end



// medical records table start

const Medicalcolumn = [
  {
    title: 'Record ID',
    dataIndex: 'id',
    key: 'ID',

  },
  {
    title: 'Date',
    dataIndex: 'Date',
    key: 'Date',
  },
  {
    title: 'TitleName',
    dataIndex: 'TitleName',
    key: 'TitleName',
  },
  {
    title: 'Name',
    dataIndex: 'HospitalName',
    key: 'HospitalName',
  },
  {
    title: 'Problems',
    dataIndex: 'Symptoms',
    key: 'Description',
  },
  {
    title: 'Attachment',
    dataIndex: 'Attachment',
    key: 'Attachment',
    render: (text) => (
      <>
        {/* <button className='client_dashboard_table_print_btn me-2'><i class="fa-solid fa-print"></i> Print</button> */}
        <a href={text} target="_blank"> <button className='client_dashboard_table_view_btn'><i class="fa-regular fa-eye"></i> View</button></a>
      </>
    ),
  },
  {
    title: 'Created',
    dataIndex: 'CreateBy',
    key: 'Created',
  },
  // {
  //   title: '',
  //   key: 'tags',
  //   dataIndex: 'tags',
  //   render: (button) => (
  //     <>
  //       <button className='client_dashboard_table_print_btn me-2'><i class="fa-solid fa-print"></i> Print</button>
  //       <button className='client_dashboard_table_view_btn'><i class="fa-regular fa-eye"></i> View</button>
  //     </>
  //   ),
  // },

];


// medical records table end



// billing table start

const BillingColumn = [
  {
    title: 'Invoice No',
    dataIndex: 'Invoice',
    key: 'InvoiceNo',

  },
  // {
  //   title: 'Doctor',
  //   dataIndex: 'Doctor',
  //   key: 'Doctor',
  // },
  {
    title: 'Amount',
    dataIndex: 'Amount',
    key: 'Amount',
  },
  {
    title: 'Paid On',
    dataIndex: 'Paidon',
    key: 'PaidOn',
  },
  {
    title: '',
    key: 'tags',
    dataIndex: 'tags',
    render: (button) => (
      <>
        <button className='client_dashboard_table_print_btn me-2'><i class="fa-solid fa-print"></i> Print</button>
        <button className='client_dashboard_table_view_btn'><i class="fa-regular fa-eye"></i> View</button>
      </>
    ),
  },

];

// billing table end



function PatientDashboard() {


  const Appointmentcolumns = [
    {
      title: 'Appt Date',
      dataIndex: 'Date',
      key: 'age',
      render: (text, record) => (
        <>
          <div className='client_dashboard_table_appdate_date'>{text}</div>
          <div className='client_dashboard_table_appdate_time'>{record['Slot']}</div>
        </>
      ),
    },
    {
      title: 'Booking Date',
      dataIndex: 'BookingDate',
      key: 'address',
    },
    {
      title: 'Purpose',
      dataIndex: 'Purpose',
      key: 'Purpose',
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'address',
    },
    // {
    //   title: 'Follow Up',
    //   dataIndex: 'FollowUp',
    //   key: 'address',
    // },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      render: (text, record) => (
        <>
          {text === 0 ? <button className='client_dashboard_table_pending_btn'>Pending</button> :
            text === -1 ? <button className='client_dashboard_table_reject_btn'>Reject</button> :
              text === 1 ? <button className='client_dashboard_table_confirm_btn'>Confirm</button> :
                text === 2 ? <button className='client_dashboard_table_confirm_btn'>Confirm</button> :
                  null


          }
        </>
      ),
    },
    {
      title: 'Bill',
      key: 'Status',
      dataIndex: 'Status',
      render: (text, record) => (
        <>

          {text === -1 ? <button className='client_dashboard_table_reject_btn' disabled>N/A</button> :
            text === 0 ? <button className='client_dashboard_table_pending_btn' disabled>N/A</button> :

              text === 1 ? <button className='client_dashboard_table_pending_btn'
                onClick={() => {

                  var Url = window.API_PREFIX + 'booking/payfees'
                  fetch(Url, {
                    method: 'POST',
                    headers: {
                      'Content-type': 'application/json',
                    },
                    body: JSON.stringify(
                      {
                        Token: localStorage.getItem('DToken'),
                        BookingId: record.BookingId,
                        Amount: record.Amount


                      }
                    ),
                  }).then(resp => resp.json()).then(data => {
                    console.log(data)
                    if (data['status'] === "1") {

                      const options = {
                        //    key: __DEV__ ? 'rzp_test_QoYTwM9rHNMFDE' : 'rzp_live_24bObjPtorMPgm',
                        key: 'rzp_test_QoYTwM9rHNMFDE',
                        amount: 20 * 100,
                        amount: Number(record.Amount) * 100,
                        order_id: data['Payment_id'],
                        // name: data['Name'],

                        description: 'Thank you for payment',
                        // image: { ArihantLogo },
                        handler: function (response) {


                          var Url = window.API_PREFIX + 'booking/verify_payment'



                          fetch(Url, {
                            method: 'POST',
                            headers: {
                              'Content-type': 'application/json',
                            },
                            body: JSON.stringify(
                              {
                                "Token": localStorage.getItem("DToken"),
                                "razorpay_order_id": response.razorpay_order_id,
                                "razorpay_payment_id": response.razorpay_payment_id,
                                "razorpay_signature": response.razorpay_signature,
                                "BookingId": record.BookingId,


                              }),
                          }).then(resp => resp.json()).then(Revdata => {
                            console.log(Revdata)
                            if (Revdata['status'] == "1") {
                              var Url = window.API_PREFIX + 'patient/view_medicalrecords'
                              fetch(Url, {
                                method: 'POST',
                                headers: {
                                  'Content-type': 'application/json',
                                },
                                body: JSON.stringify(
                                  {
                                    Token: localStorage.getItem('DToken'),


                                  }
                                ),
                              }).then(resp => resp.json()).then(data => {
                                console.log(data)
                                if (data['status'] === "1") {
                                  setuserMedicalData([...data['records']])
                                }
                              })
                              setDashboardOption([false, false, false, true])

                            }
                          })


                        },
                        prefill: {
                          name: "",
                          email: "",
                          phone_number: ""
                        }
                      };
                      const paymentObject = new window.Razorpay(options)
                      paymentObject.open()




                    }
                  })
                }}
              >Pay Amount</button> :
                text === 2 ? <><button className='client_dashboard_table_confirm_btn' disabled>Paid</button>
                  {/* <button className='client_dashboard_table_view_btn'><i class="fa-regular fa-eye"></i> View</button> */}
                </> :
                  null


          }


        </>
      ),
    },

  ];


  const addmedicalrecordpopup = {
    borderRadius: "10px",
    backgroundColor: "white",
    // boxshadow: "1 1 20px rgba(255, 255, 255, 0.4)",
    borderradius: "5px",
    padding: "0px",
    width: "100%",
    height: "max-content",
    margin: "160px 450px"
  }
  const [{ IsRegister }, dispatch] = useStateValue()
  const [userAppointmentData, setuserAppointmentData] = useState([])
  const [userPrescribeData, setuserPrescribeData] = useState([])
  const [userMedicalData, setuserMedicalData] = useState([])
  const [addMedicalRecord, setAddMedicalRecord] = useState(false)
  const [TitleName, setTitleName] = useState("")
  const [HospitalName, setHospitalName] = useState("")
  const [Symptoms, setSymptoms] = useState("")
  const [RecordDate, setRecordDate] = useState("")
  const [MedicalRecord, setMedicalRecord] = useState()


  const [userBillingData, setuserBillingData] = useState([])
  const [userData, setuserData] = useState({})

  useEffect(() => {
    // dispatch({type:"Register"})
    var Url = window.API_PREFIX + 'patient/userwiseappointment'
    fetch(Url, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(
        {
          Token: localStorage.getItem('DToken'),


        }
      ),
    }).then(resp => resp.json()).then(data => {
      console.log(data)
      if (data['status'] === "1") {
        setuserAppointmentData([...data['appointment']])
      }
    })


    var Url = window.API_PREFIX + 'patient/view_profile'
    fetch(Url, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(
        {
          Token: localStorage.getItem('DToken'),


        }
      ),
    }).then(resp => resp.json()).then(data => {
      console.log(data)
      if (data['status'] === "1") {
        setuserData({ ...data })
      }
    })





  }, [])


  const [DashboardOption, setDashboardOption] = useState([true, false, false, false, false])


  // responsive table start

  let navigate = useNavigate();



  // responsive table end


  return (
    <>
      {/* breadcrumbs-start */}
      {/* Navtag-start */}
      <nav className='client_dashboard_breadcrumb_main_division'>
        <div className='client_dashboard_breadcrumb_division'>
          <p className='client_dashboard_breadcrumb_title'>home</p>
          <p className='client_dashboard_breadcrumb_title1'>/</p>
          <p className='client_dashboard_breadcrumb_title2'>dashboard</p>
        </div>
        <div>
          <h1 className='client_dashboard_breadcrumb_title_main'>dashboard</h1>
        </div>
      </nav>
      {/* Navtag-end */}
      {/* breadcrumbs-end */}
      {/* maintag-start */}
      <main className="container-fuild">
        <div className='slidebar_and_component'>
          <PatientSidebar />

          <div className='component_division'>
            <div className='client_dashboard_main_division'>


              <div class="client_bashboard_tab_division">
                <div className={DashboardOption[0] ? 'col client_bashboard_tabs_title_name_division_active' : 'col client_bashboard_tabs_title_name_division'}

                  onClick={() => {

                    var Url = window.API_PREFIX + 'patient/userwiseappointment'
                    fetch(Url, {
                      method: 'POST',
                      headers: {
                        'Content-type': 'application/json',
                      },
                      body: JSON.stringify(
                        {
                          Token: localStorage.getItem('DToken'),


                        }
                      ),
                    }).then(resp => resp.json()).then(data => {
                      console.log(data)
                      if (data['status'] === "1") {
                        setuserAppointmentData([...data['appointment']])
                      }
                    })
                    setDashboardOption([true, false, false, false])
                  }}>

                  <div class="client_bashboard_tabs_title_name">Appointments</div>
                </div>
                <div className={DashboardOption[1] ? 'col client_bashboard_tabs_title_name_division_active' : 'col client_bashboard_tabs_title_name_division'}


                  onClick={() => {

                    var Url = window.API_PREFIX + 'admin/view_prescription'
                    fetch(Url, {
                      method: 'POST',
                      headers: {
                        'Content-type': 'application/json',
                      },
                      body: JSON.stringify(
                        {
                          Token: localStorage.getItem('DToken'),



                        }
                      ),
                    }).then(resp => resp.json()).then(data => {
                      console.log(data)
                      if (data['status'] === "1") {
                        setuserPrescribeData([...data['data']])
                      }
                    })
                    setDashboardOption([false, true, false, false])
                  }}>
                  <div class="client_bashboard_tabs_title_name">Framework</div>
                </div>
                <div className={DashboardOption[2] ? 'col client_bashboard_tabs_title_name_division_active' : 'col client_bashboard_tabs_title_name_division'}

                  onClick={() => {

                    var Url = window.API_PREFIX + 'patient/view_medicalrecords'
                    fetch(Url, {
                      method: 'POST',
                      headers: {
                        'Content-type': 'application/json',
                      },
                      body: JSON.stringify(
                        {
                          Token: localStorage.getItem('DToken'),


                        }
                      ),
                    }).then(resp => resp.json()).then(data => {
                      console.log(data)
                      if (data['status'] === "1") {
                        setuserMedicalData([...data['records']])
                      }
                    })
                    setDashboardOption([false, false, true, false])
                  }}
                >
                  <div class="client_bashboard_tabs_title_name">Files</div>
                </div>
                <div className={DashboardOption[3] ? 'col client_bashboard_tabs_title_name_division_active' : 'col client_bashboard_tabs_title_name_division'}

                  onClick={() => {

                    var Url = window.API_PREFIX + 'patient/billingpatientwise'
                    fetch(Url, {
                      method: 'POST',
                      headers: {
                        'Content-type': 'application/json',
                      },
                      body: JSON.stringify(
                        {
                          Token: localStorage.getItem('DToken'),


                        }
                      ),
                    }).then(resp => resp.json()).then(data => {
                      console.log(data)
                      if (data['status'] === "1") {
                        setuserBillingData([...data['Bill']])
                      }
                    })
                    setDashboardOption([false, false, false, true])
                  }}>
                  <div class="client_bashboard_tabs_title_name">Billing</div>
                </div>
              </div>

              {DashboardOption[0] ? <div className='client_dashboard_table_division'><Table columns={Appointmentcolumns} dataSource={userAppointmentData} /></div> :
                DashboardOption[1] ?
                  <div className='client_dashboard_table_division'><Table columns={Prescribecolumns} dataSource={userPrescribeData} /></div> :
                  DashboardOption[2] ? <>
                    <div className='client_medicalrecords_add_records_section'
                      onClick={() => {
                        setAddMedicalRecord(true)
                      }}
                    >
                      <button type="button" className=" client_medical_records_add_records_button btn">Add Files</button>
                      {/* This css is in client Medical record */}
                    </div>
                    <div className='client_dashboard_table_division'><Table columns={Medicalcolumn} dataSource={userMedicalData} /></div></> :
                    <div className='client_dashboard_table_division'><Table columns={BillingColumn} dataSource={userBillingData} /></div>}
            </div>
          </div>





        </div>

        <Popup
          contentStyle={addmedicalrecordpopup}
          modal
          open={addMedicalRecord}
          onClose={() => { setAddMedicalRecord(false) }}
        >
          <div className='Add_medical_records_popup_div'>
            <div className='add_medical_records_popuop_header'>
              <h5 className='add_medical_records_records_title'>Add Files</h5>
              <i onClick={() => { setAddMedicalRecord(false) }} class=" add_medical_records_popuop_colse_button fa-solid fa-xmark"></i>
            </div>
            <div className='add_medical_records_popup_body'>
              <div className='popup_body_div_two'>
                <div className='popup_body_name_client_section row'>
                  <div className='col-12 col-md-6'>
                    <p className='popup_body_name_title'>Title Name</p>
                    <input type="text" name="description" class="addmedical_pop_text_feild form-control" placeholder="Enter Title Name"
                      onChange={(e) => {
                        setTitleName(e.target.value)
                      }}
                    />
                  </div>
                  {/* <div className='col-12 col-md-6'>
                                                        <p className='popup_body_client_title'>client</p>
                                                        <select class=" popup_input_tags form-select" aria-label="Default select example">
                                                            <option selected>Open this select menu</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                            <option value="3">Three</option>
                                                        </select>
                                                    </div> */}
                </div>
                <br></br>
                <div className='popup_body_hospital_name_section'>
                  <p className='popup_body_hospital_title'>Name</p>
                  <input type="text" name="description" class=" addmedical_pop_text_feild form-control" placeholder="Enter Name Here.."
                    onChange={(e) => {
                      setHospitalName(e.target.value)
                    }}
                  />
                </div>
                <br></br>
                <div>
                  <p className='popup_body_upload_title'>Upload</p>
                  <div class="  input-group">
                    <input class=" form-control" id="inputGroupFile02"
                      style={{ "cursor": "pointer" }}
                      type="file"
                      name="myImage"
                      //  accept='image/png/jpeg'
                      onChange={(event) => {

                        setMedicalRecord(event.target.files[0]);

                      }}

                    />
                    <label class="input-group-text" for="inputGroupFile02">Upload</label>
                  </div>
                </div>
                <br></br>
                <div>
                  <p className='popup_body_symptoms_title'>Other Details</p>
                  <input type="text" name="description" class=" addmedical_pop_text_feild form-control" placeholder=""
                    onChange={(e) => {
                      setSymptoms(e.target.value)
                    }}
                  />
                </div>
                <br></br>
                <div>
                  <p className='popup_body_symptoms_title '>Date</p>
                  <input className=' col-12 col-md-5 popup_body_date_section' type="date"
                    onChange={(e) => {
                      setRecordDate(e.target.value)
                    }}
                  />
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Basic example"
                                        value={value}
                                        onChange={(newValue) => {
                                            setValue(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider> */}
                </div>
                <br></br>
                <div className='popup_submit_button_section'>
                  <button type="button" className="popup_submit_button btn"
                    onClick={() => {
                      setAddMedicalRecord(false)
                      var Url = window.API_PREFIX + 'patient/add_medicalrecords'

                      var uploadData = new FormData();
                      uploadData.append("Token", localStorage.getItem("DToken"));
                      uploadData.append("TitleName", TitleName);
                      uploadData.append("HospitalName", HospitalName);
                      uploadData.append("Symptoms", Symptoms);
                      uploadData.append("RecordDate", RecordDate);
                      uploadData.append("Attachment", MedicalRecord);
                      fetch(Url, {
                        method: 'POST',
                        // headers: {
                        //     'Content-type': 'application/json',
                        // },
                        body: uploadData
                      }).then(resp => resp.json()).then(data => {
                        console.log(data)
                        if (data['status'] === "1") {
                          alert("Medical Record Added")
                          var tmp = userMedicalData
                          tmp.push(
                            {

                              "id": data['id'],
                              "Date": RecordDate,
                              "Symptoms": Symptoms,
                              "Attachment": data['Pdf'],
                              "CreateBy": data['createdby'],
                              "HospitalName": HospitalName,
                              "TitleName": TitleName

                            }
                          )
                          setuserMedicalData([...tmp])
                          setAddMedicalRecord(false)

                        }
                      })
                    }}
                  >Submit</button>
                </div>
              </div>
            </div>
          </div>

        </Popup>


      </main>
      {/* maintag-end */}
    </>
  )
}
export default PatientDashboard;
