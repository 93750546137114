import patientdashboardimg from '../../media/image.jpg';
import { useNavigate } from 'react-router-dom';
import './DoctorSidebar.css';
import './Sidebar.css';
import '../../App.css';
import { useEffect, useState } from 'react';
import { useStateValue } from '../StateProviders';
export default function DoctorSidebar() {

  const [{ Loading }, dispatch] = useStateValue();

  let navigate = useNavigate();
  const [DocData, setDocData] = useState({});
  useEffect(() => {

    var Url = window.API_PREFIX + 'admin/doctordetail'
    fetch(Url, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(
        {
          Token: localStorage.getItem('DToken'),


        }
      ),
    }).then(resp => resp.json()).then(data => {
      console.log(data)
      if (data['status'] === "1") {

        setDocData({ ...data['data'] })

      }
    })



  }, [])


  return (
    <div className='sidebar_main_division'>
      {/* <div className='sidebar_division'> */}
        {/* <div className='sidebar_img_profile_main_division'>
          <div className='sidebar_img_profile_division'><img className='sidebar_img_profile' src={DocData['Image']}></img></div>
        </div>
        <div className='sidebar_profile_person_name'>{DocData['Name']}</div> */}
        {/* <div className='sidebar_birthdate_division'><i class="sidebar_birthdate_icon_cake_location fa-solid fa-cake-candles"></i> 24 Jul 1983, 38 years</div> */}
        {/* <div className='sidebar_location_division'><i class="sidebar_birthdate_icon_cake_location fa-solid fa-location-dot"></i> {DocData['Address']}</div> */}
      {/* </div> */}

      <div className={window.location.pathname.includes('/coach/dashboard') ? 'sidebar_dashboard_title_division_active' : "sidebar_dashboard_title_division"}
        onClick={() => {

          navigate('/coach/dashboard')
        }}
      ><i class="fa-solid fa-table-columns me-2"></i> Dashboard</div>

      <div className={window.location.pathname.includes('/coach/doctorappointmentbydate') ? 'sidebar_dashboard_title_division_active' : "sidebar_dashboard_title_division"}
        onClick={() => {

          navigate('/coach/doctorappointmentbydate')
        }}
      ><i class="fa-solid fa-table-columns me-2"></i> Appointment By Date</div>

      <div className={window.location.pathname.includes('/coach/my-clients') ? 'sidebar_dashboard_title_division_active' : 'sidebar_dashboard_title_division'}
        onClick={() => {

          navigate('/coach/my-clients')

        }}
      ><i class="fa-solid fa-file-invoice-dollar me-2"></i> My Clients</div>
      <div className={window.location.pathname.includes('/coach/schedule-timing') ? 'sidebar_dashboard_title_division_active' : 'sidebar_dashboard_title_division'}
        onClick={() => {
          navigate('/coach/schedule-timing')

        }}
      ><i class="fa-solid fa-file-invoice-dollar me-2"></i>Schedule Timing</div>
      {/* <div className='sidebar_dashboard_title_division'><i class="fa-solid fa-file-medical"></i> Medical Details</div> */}

      {/* <div className={window.location.pathname.includes('/coach/patientprofile/:PID') ? 'coachsidebar_dashboard_title_division_active' : 'coachsidebar_dashboard_title_division'}
                                onClick={() => {
                                    navigate('/coach/patientprofile/:PID')

                                }}
                            ><i class="fa-solid fa-gears"></i> Profile Settings</div> */}
      <div className={window.location.pathname.includes('/coach/doctorchangepassword') ? 'sidebar_dashboard_title_division_active' : 'sidebar_dashboard_title_division'}
        onClick={() => {
          navigate('/coach/doctorchangepassword')

        }}
      ><i class="fa-solid fa-lock me-2"></i> Change Password </div>

      <div className='sidebar_dashboard_title_division'
        onClick={() => {
          localStorage.clear()
          navigate('/')

        }}
      ><i class="fa-solid fa-right-from-bracket me-2"></i> Logout </div>

    </div>
  )
}