import React from "react";
import "./Footer.css";
import Footerlogo from "../media/about/vralogo.png";
import googleplay from '../media/about/googleplay.png'
import appstore from '../media/about/appstore.png'


function Footer() {
  return (

    // Footer-start
    <footer className="footer_main_division ">

      {/* <div className="subscribe_main_div">
          <h4 className="footer_title" style={{ fontSize: '2.125rem' }}>Subscribe  to our Expertise </h4>
          <div >
            <input type="email" class="form-control input_div" id="exampleFormControlInput1" placeholder="Email" />
          </div>
        </div> */}


      {/* <div className="footer_about_us">
        <h5 className="footer_h5">About US</h5>
      </div> */}


      <div className=" row d-flex justify-content-between align-items-center">
        <div className=" col-md-4 col-lg-4  left_part">
          <a href="/">
            <img className="footer_logo_img" src={Footerlogo} />
          </a>
        </div>
        {/* <div className="phone_div " >
                <i class="fa-solid fa-phone fa-xl" style={{color:'black'}}></i>
              <div className="number">
                <a className="anchor" href="tel:+918160082609">+91 8160082609</a>
              </div>
            </div>
            <div className="phone_div " >
                <i class="fa-solid fa-envelope fa-xl" style={{color:'black'}}></i>
              <div className="number">
                <a className="anchor" href="mailto:someone@example.com">vinnyritu</a>
              </div>
            </div> */}
        <div className="col-md-4 col-lg-3 center_part">
          <p> OUR APPLICATION IS AVAILABLE ON </p>
          <div className="application_link">
            <a href="">
              <img src={googleplay} className="google_play"></img>
            </a>
            <a href="">
              <img src={appstore} className="app_store"></img>
            </a>
          </div>
          <div class='keep_in_touch'> KEEP IN TOUCH </div>
          <div className="footer_social_icon_division">
            <div>
              <a
                className="footer_for_patient_link"
                href="##/"
              >
                <i className="fa-brands fa-facebook-f footer_social_icon"></i>
              </a>
            </div>
            <div>
              <a
                className="footer_for_patient_link"
                href="##"
              >
                <i className="fa-brands fa-twitter footer_social_icon"></i>
              </a>
            </div>
            <div>
              <a
                className="footer_for_patient_link"
                href="##"
              >
                <i className="fa-brands fa-linkedin footer_social_icon"></i>
              </a>
            </div>
            <div>
              <a
                className="footer_for_patient_link"
                href="https://www.instagram.com/vinnyrituagarwal/"

              >
                <i className="fa-brands fa-instagram footer_social_icon"></i>
              </a>
            </div>
            <div>
              <a
                className="footer_for_patient_link"
                href="##"
              >
                <i className="fa-sharp fa-solid fa-globe footer_social_icon"></i>
              </a>
            </div>
          </div>
        </div>


        <div className=" col-md-4 col-lg-4  ">

          <div className="subscribe_main_div">
            <h4 className="footer_title" >Subscribe  to our Expertise </h4>
            <div className="email_footer">
              <input type="email" class="form-control input_div" id="exampleFormControlInput1" placeholder="Email" />
              <button type="button" class="btn button_right"><svg
                fill="white" className="right_mail" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg></button>
            </div>
          </div>


          {/* <div className="footer_social_icon_division">
              <div>
                <a
                  className="footer_for_patient_link"
                  href="https://www.facebook.com/NYSHASOLUTIONS/"
                >
                  <i className="fa-brands fa-facebook-f footer_social_icon"></i>
                </a>
              </div>
              <div>
                <a
                  className="footer_for_patient_link"
                  href="https://twitter.com/nyshasolutions"
                >
                  <i className="fa-brands fa-twitter footer_social_icon"></i>
                </a>
              </div>
              <div>
                <a
                  className="footer_for_patient_link"
                  href="https://www.linkedin.com/company/nyshasolutions/?originalSubdomain=in"
                >
                  <i className="fa-brands fa-linkedin footer_social_icon"></i>
                </a>
              </div>
              <div>
                <a
                  className="footer_for_patient_link"
                  href="https://www.instagram.com/nyshasolutions/"
                >
                  <i className="fa-brands fa-instagram footer_social_icon"></i>
                </a>
              </div>
              <div>
                <a
                  className="footer_for_patient_link"
                  href="https://nyshasolutions.com/"
                >
                  <i className="fa-sharp fa-solid fa-globe footer_social_icon"></i>
                </a>
              </div>
            </div> */}
        </div>





      </div>

      <div className=" footer_copyright">
        <div className="footer_copyright_second_div">
          <div className="footer_copy">
            <div className="copyright_text">
              <label className="footer_copyright_label">
                Copyright <i class="fa-regular fa-copyright"></i> 2024 |
                Developed by{" "}
                <a
                  className="footer_copyright_a"
                  href="https://www.kodenauts.com/"
                >
                  KodeNauts
                </a>
              </label>
            </div>
          </div>
          <div className="footer_copy_pr">
            <div className="copyright_menu">
              <label className="footer_copyright_policy_label">
                <a className="footer_tc" href="/Terms&Conditions">
                  Terms & Conditions
                </a>{" "}
                |{" "}
                <a className="footer_tc" href="/Privacypolicy">
                  Privacy Policy
                </a>
              </label>
            </div>
          </div>
        </div>
      </div>


    </footer>
    // Footer-end

  );
}

export default Footer;
