import patientdashboardimg from '../../media/image.jpg';
import '../../App.css';
import './PatientSidebar.css'
import '../Patient/Sidebar.css'
import { useStateValue } from '../StateProviders';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
export default function PatientSidebar() {
  let navigate = useNavigate()
  const [{ isLogin }, dispatch] = useStateValue()
  const [DocData, setDocData] = useState({});
  useEffect(() => {
    var Url = window.API_PREFIX + 'admin/Singledoctordetail'
    fetch(Url, {
      method: 'GET',

    }).then(resp => resp.json()).then(data => {
      // console.log("sv-->",data)
      if (data['status'] === "1") {

        setDocData({ ...data['data'] })

      }
    })



  }, [])



  return (
    // Asidetag-start
    <aside className='sidebar_main_division'>
      {/* <div className='sidebar_division'> */}
        {/* <div className='sidebar_img_profile_main_division'> */}
          {/* <div className='sidebar_img_profile_division'><img className='sidebar_img_profile' src={DocData['Image']}></img></div> */}
        {/* </div> */}
        {/* <div className='sidebar_profile_person_name'>{DocData['Name']}</div> */}
        {/* <div className='sidebar_birthdate_division'><i class="sidebar_birthdate_icon_cake_location fa-solid fa-cake-candles"></i> </div> */}
        {/* <div className='sidebar_location_division'><i class="sidebar_birthdate_icon_cake_location fa-solid fa-location-dot"></i>  {DocData['Address']}</div> */}
      {/* </div> */}
      <div className={window.location.pathname.includes('/dashboard') ? 'sidebar_dashboard_title_division_active' : "sidebar_dashboard_title_division"}
        onClick={() => {

          navigate('/dashboard')
        }}
      ><i class="fa-solid fa-table-columns me-2"></i> Dashboard</div>
      <div className={window.location.pathname.includes('/booking') ? 'sidebar_dashboard_title_division_active' : "sidebar_dashboard_title_division"}
        onClick={() => {

          navigate('/booking')
        }}
      ><i class="fa-solid fa-file-invoice-dollar me-2"></i> Book Appointment</div>

      <div className={window.location.pathname.includes('/profilesetting') ? 'sidebar_dashboard_title_division_active' : "sidebar_dashboard_title_division"}
        onClick={() => {

          navigate('/profilesetting')
        }}
      ><i class="fa-solid fa-gears me-2"></i> Profile Settings</div>
      <div className={window.location.pathname.includes('/changepassword') ? 'sidebar_dashboard_title_division_active' : "sidebar_dashboard_title_division"}
        onClick={() => {

          navigate('/changepassword')
        }}
      ><i class="fa-solid fa-lock me-2" ></i> Change Password </div>
      <div className='sidebar_dashboard_title_division'
        onClick={() => {
          localStorage.clear()
          dispatch({
            type: "Logout"
          })
          navigate("")
        }}
      ><i class="fa-solid fa-right-from-bracket me-2"></i> Logout </div>

    </aside>
    // Asidetag-end
  )
}