// import { Header } from "antd/lib/layout/layout";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom"
import Footer from "../Footer";
import Simpledoctorheader from '../Simpledoctorheader';
import { useStateValue } from "../StateProviders";
import DoctorLogin from "./DoctorLogin";
export default function Adminlayout()
{

    const [{DIsLogin },dispatch] = useStateValue();
    let navigate = useNavigate();

    useEffect(() => {
      if (DIsLogin) {
        navigate("/coach/dashboard");
      }
    }, [DIsLogin]);   

useEffect(() => {
  if (localStorage.getItem("DToken") !== null && localStorage.getItem("LoginDetails") === 'Admin') {
    dispatch({
      type: "DLogin",
    });
  } else {
    dispatch({
      type: "DLogout",
    });
  }
}, []);
    return (<>
   {/* <Header /> */}
   <Simpledoctorheader/>
    {DIsLogin?
    <Outlet/>:<DoctorLogin/>}
    <Footer/>
    </>)
}