import './Schedule_Timings.css';
import '../../App.css';
import Popup from "reactjs-popup";
import { useEffect, useState } from 'react';
import { useStateValue } from '../StateProviders';
import { type } from '@testing-library/user-event/dist/type';
import DoctorSidebar from './DoctorSidebar';
import './Sidebar.css'


const EditPopup = {
    borderRadius: "10px",
    backgroundColor: "white",
    boxshadow: "1 1 20px rgba(255, 255, 255, 0.4)",
    borderradius: "5px",
    padding: "0px",
    width: "35%",
    height: "max-content",
    margin: "160px 450px"
}
function Schedule_Timing() {

    const WeekDay = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    const [Weekdayactive, setWeekdayActive] = useState(null)
    const [{ IsLogin }, dispatch] = useStateValue();

    const [TimeSlot, setTimeSlot] = useState([])

    const [TimeSlotactive, setTimeSlotActive] = useState([])

    const [SaveText, setSaveText] = useState(false)

    const [Price, setPrice] = useState("")
    const [TimeDuration, setTimeDuration] = useState("15")




    const [TimePopup, setTimePopup] = useState(false)
    return (
        <>
            {/* breadcrumbs-start */}
            <nav className='schedule_timing_breadcrumb_main_division'>
                <div className='schedule_timing_breadcrumb_division'>
                    <p className='schedule_timing_breadcrumb_title'>home</p>
                    <p className='schedule_timing_breadcrumb_title1'>/</p>
                    <p className='schedule_timing_breadcrumb_title2'>schedule timing</p>
                </div>
                <div>
                    <h1 className='schedule_timing_breadcrumb_title_main'>schedule timings</h1>
                </div>
            </nav>
            {/* breadcrumbs-end */}

            <div className="container-fuild">
                <div className='slidebar_and_component'>
                    <DoctorSidebar />
                    <div className='component_division'>
                        <div className='schedule_timings_main_diviison'>
                            <p className='schedule_timings_title'>schedule timings</p>
                            <div className='schedule_timings_diviison'>
                                <div className='schedule_timings_week_division'>
                                    {WeekDay.map((eachDay, DayIndex) => {
                                        return (<div
                                            // className='schedule_timings_week_name'

                                            onClick={() => {


                                                setSaveText(false)

                                                var Url = window.API_PREFIX + 'timing/showslot_advance_doctor'

                                                fetch(Url, {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-type': 'application/json',
                                                    },
                                                    body: JSON.stringify(
                                                        {
                                                            'Token': localStorage.getItem('DToken'),
                                                            Tag: DayIndex

                                                        }
                                                    ),
                                                }).then(resp => resp.json()).then(data => {
                                                    console.log(data)

                                                    if (data['status'] === "1") {
                                                        setTimeSlot([...data["TotalSlot"]])
                                                        setTimeSlotActive([...data['ActiveSlot']])




                                                    }
                                                }
                                                )
                                                setWeekdayActive(eachDay)


                                            }}
                                            className={Weekdayactive === eachDay ? 'schedule_timings_week_name_active' : 'schedule_timings_week_name'}>
                                            {eachDay}
                                        </div>)

                                    })}

                                </div>
                                <div className='schedule_timings_time_slot_division'>
                                    <div className='schedule_timings_time_slote_title'>time slots</div>

                                    {/* {Weekdayactive!==null?
                                    <div className='schedule_timings_time_slot_edit_icon' onClick={()=>{
                                        setTimePopup(true)
                                    }}
                                    ><i class="fa-solid fa-pen-to-square"></i>Edit</div>:null} */}
                                    {/* <Popup modal  
                                    open = {TimePopup}
                                    onClose={() => {
                                        setTimePopup(false)
                                    }}
                                        contentStyle={EditPopup}>
                                        <div className='schedule_timings_edit_popup_main_division'>
                                            <div className='schedule_timings_edit_popup_division'>
                                                <div className='schedule_timings_edit_popup_EditTimeSlot_name'>
                                                    Edit Time Slot
                                                </div>
                                                <div onClick={() => {
                                                    setTimePopup(false)
                                                }}><i class="fa-solid fa-xmark"></i></div>
                                            </div>
                                            <div className='schedule_timings_edit_popup_content'>
                                                <div className='schedule_timings_edit_popup_timingslot_select_division'>
                                                    <label for="inputState" class="form-label">Timing Slot Duration</label>
                                                    <select id="inputState" class="form-select schedule_timings_edit_popup_timingslot_select"
                                                    
                                                    onChange={(e)=>{
                                                        setTimeDuration(e.target.value)
                                                    }}>
                                                    
                                                        <option>15</option>
                                                        <option>30</option>
                                                        <option>45</option>
                                                        <option>60</option>
                                                    </select>
                                                </div>
                                                <p className='schedule_timings_edit_popup_priceslote_name'>Price For Slot</p>
                                                <input type="number" id="lname" className='schedule_timings_edit_popup_price_input' placeholder='Price'
                                                onChange={(e)=>{setPrice(e.target.value)}}
                                                />
                                               <div className='schedule_timings_clear_current_dayslot_button_division'> 
                                               <button type="button" class="btn schedule_timings_clear_current_dayslot_button"
                                               onClick={()=>{
     
                                                if(Price!==""){
                                                var Url = window.API_PREFIX + 'timing/Time_Slot'
                                                fetch(Url, {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-type': 'application/json',
                                                    },
                                                    body: JSON.stringify(
                                                        {
                                                            Token:localStorage.getItem('DToken'),
                                                            Duration:TimeDuration
    
                                                        }
                                                    ),
                                                }).then(resp => resp.json()).then(data => {
                                                    console.log(data)
                                                    if (data['status'] === "1") {
                                              setTimeSlot([...data['Slot']])
                                              setTimePopup(false)
                                                }
                                            }
                                                )}

                                               }}
                                               >Clear Current Day Slot</button>
                                               </div>

                                            </div>
                                        </div>
                                    </Popup> */}
                                    {/* <div className='schedule_timings_time_slot_edit_icon'><i class="fa-solid fa-pen-to-square"></i>Edit</div> */}
                                </div>


                                <div className='schedule_timings_time_slot_table_division'>

                                    {TimeSlot.map((eachTimeSlot, TimeSlotindex) => {

                                        return (
                                            <div className={TimeSlotactive[TimeSlotindex] ? 'schedule_timings_time_slots_active' : 'schedule_timings_time_slots'}
                                                onClick={
                                                    () => {
                                                        setSaveText(false)

                                                        var tmpFlag = TimeSlotactive
                                                        tmpFlag[TimeSlotindex] = !tmpFlag[TimeSlotindex]
                                                        setTimeSlotActive([...tmpFlag])
                                                    }
                                                }
                                            >{eachTimeSlot}</div>)
                                    })}



                                </div>
                                <div className='schedule_timings_save_slot_button_division'>
                                    <button type="button" class="btn schedule_timings_save_slot_button"
                                        onClick={() => {


                                            var Url = window.API_PREFIX + 'timing/update_slot'
                                            fetch(Url, {
                                                method: 'POST',
                                                headers: {
                                                    'Content-type': 'application/json',
                                                },
                                                body: JSON.stringify(
                                                    {
                                                        'Token': localStorage.getItem('DToken'),
                                                        DayTag: WeekDay.indexOf(Weekdayactive),
                                                        SlotActive: TimeSlotactive,

                                                    }
                                                ),
                                            }).then(resp => resp.json()).then(data => {
                                                console.log(data)
                                                if (data['status'] === "1") {

                                                    setSaveText(true)

                                                }
                                            }
                                            )


                                        }}
                                        disabled={SaveText}
                                    >{SaveText ? "Saved" : "Save Slot"}</button>
                                </div>


                            </div>


                            <div className='d-flex justify-content-center mt-3'>
                                <div>
                                    <i class="fa-solid fa-square me-2 available_1"></i>
                                    Availble
                                </div>
                                <div className='ms-3'>
                                    <i class="fa-solid fa-square me-2 available_2"></i>
                                    Not Availble
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Schedule_Timing;