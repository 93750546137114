import React from "react";
import '../../App.css';
import "./PatientProfilesettings.css";
import profilepic from "../../media/image.jpg";
import PatientSidebar from "./PatientSidebar";
import '../Patient/Sidebar.css'
import Patientsidebarresponsive from "./Patientsidebarresponsive";
import { useState, useEffect } from "react";


function PatientProfilesettings() {

  const [name, setname] = useState("")
  const [Email, setEmail] = useState("")
  const [Phnumber, setPhnumber] = useState("")
  const [Img, setImg] = useState("")
  const [gender, setgender] = useState("male")
  const [Weight, setWeight] = useState("")
  const [height, setheight] = useState("")
  const [profession, setprofession] = useState("");
  const [age, setage] = useState("")

  const [Address, setAddress] = useState("")
  const [City, setCity] = useState("")
  const [State, setState] = useState("")
  const [Country, setCountry] = useState("")
  const [OtherInfo, setOtherInfo] = useState("")
  const [file, setFile] = useState();
  const [Pincode, setPincode] = useState('');
  const [showImg, setshowImg] = useState();
  const [Birthdate, setBirthdate] = useState();


  useEffect(() => {
    var Url = window.API_PREFIX + 'patient/view_profile'
    fetch(Url, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(
        {
          Token: localStorage.getItem('DToken'),
        }
      ),
    }).then(resp => resp.json()).then(data => {
      console.log(data)
      if (data['status'] === "1") {
        setname(data['Name'])
        setPhnumber(data['Mobile'])
        setImg(data['Image'])
        setgender(data['Gender'])
        setWeight(data['Weight'])
        setheight(data['Height'])
        setprofession(data['setprofession'])

        setAddress(data['Address'])
        setCity(data['City'])
        setState(data['State'])
        setCountry(data['Country'])
        setOtherInfo(data['OtherInformation'])
        setEmail(data['Email'])
        setshowImg(data['Image'])
        // setFile(data['Name']) 
        setPincode(data['Pincode'])
        setBirthdate(data['Birthdate'])


      }
    })
  }, [])
  return (
    <>
      {/* breadcrumbs-start */}
      {/* Navtag-start */}
      <nav className="Profilesettings_breadcrumb_main_division">
        <div className="Profilesettings_breadcrumb_division">
          <p className="Profilesettings_breadcrumb_title">home</p>
          <p className="Profilesettings_breadcrumb_title1">/</p>
          <p className="Profilesettings_breadcrumb_title2">Profile Settings</p>
        </div>
        <div>
          <h1 className="Profilesettings_breadcrumb_title_main">
            Profile Settings
          </h1>
        </div>
      </nav>
      {/* Navtag-end */}
      {/* breadcrumbs-end */}

      {/* maintag-start */}
      <main className="slidebar_and_component">
        <PatientSidebar />
        {/* <Patientsidebarresponsive/> */}
        <div className="component_division">
          <div className="Profilesettings_main_diviison">
            <div className="profilesettings_Upload_image">
              <div className="profilesettings_profile_image">
                <img
                  className="profilesettings_profile_image_sizes"
                  src={showImg}
                />
              </div>
              <div className="profilesettings_upload_button_main">
                <button type="button" className="profilesettings_upload_photo_button btn"

                >
                  <i className="fa fa-upload profilesettings_upload_icon"></i> Upload Photo
                  <input type="file" accept="image/gif, image/jpeg, image/png"
                    onChange={(e) => {

                      setImg(e.target.files[0])

                      setshowImg(URL.createObjectURL(e.target.files[0]));
                    }}
                  />
                </button>
                <br />
                {/* ... */}
                <div className="profilesetting_upload_p">
                  <p>Allowed JPG, GIF or PNG. Max size of 20MB</p>
                </div>
                {/* .. */}
              </div>
            </div>
            <div className="profilesetting_input_form">
              <div className="row g-3 profilesetting_form">
                <div className="col-md-6 profilesettings_first_name">
                  <label for="inputfirstaname" className="form-label profilesettings_label">Name</label>
                  <input type="text" className="form-control profilesettings_input" placeholder="Enter Your Name" id="inputfirstname" value={name}
                    onChange={(e) => {
                      setname(e.target.value)

                    }} />
                </div>
                <div className="col-md-6 profilesettings_mobile">
                  <label for="inputmobile" className="form-label profilesettings_label">Mobile Number</label>
                  <input type="textfield" className="form-control profilesettings_input" id="inputmobile" placeholder="Enter Your Mobile Number" value={Phnumber}
                    onChange={(e) => {
                      setPhnumber(e.target.value)

                    }} />
                </div>

                <div className="col-md-6 profilesettings_email">
                  <label for="inputemail" className="form-label profilesettings_label">Email ID</label>
                  <input type="email" className="form-control profilesettings_input" placeholder="Enter Your Email Address" id="inputemail" disabled value={Email} />
                </div>
                <div className="col-md-6 profilesettings_Date_of_birth">
                  <label for="inputdateofbirth" className="form-label profilesettings_label">Date Of Birth</label>
                  <input type="date" className="form-control profilesettings_input" disabled id="inputdateofbirth" value={Birthdate}
                    onChange={(e) => {
                      setBirthdate(e.target.value)


                      var today = new Date();
                      var birthDate = new Date(e.target.value);
                      var age_now = today.getFullYear() - birthDate.getFullYear();
                      var m = today.getMonth() - birthDate.getMonth();
                      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                        age_now--;
                      }

                      setage(age_now)
                    }}
                  />
                </div>
                {/* <div className="profilesettings_details col-md-6"> */}
                {/* <div className="col-12 profilesettings_weight">
                      <label for="inputweight" className="form-label profilesettings_label">Your Weight</label>
                      <input type="text" className="form-control profilesettings_input" id="inputweight" placeholder="Enter Your Weight In KG" value={Weight}
                        onChange={(e) => {
                          setWeight(e.target.value)

                        }}
                      />
                    </div> */}
                {/* <div className="col-md-6 profilesettings_height">
                      <label for="inputheight" className="form-label profilesettings_label">Your Height</label>
                      <input type="text" className="form-control profilesettings_input" id="inputheight" placeholder="Enter Your Height In CM/ FEET/ INCH" value={height}
                        onChange={(e) => {
                          setheight(e.target.value)

                        }}
                      />
                    </div> */}
                <div className="col-md-6 profilesettings_Age">
                  <label for="inputage" className="form-label profilesettings_label">Age</label>
                  <input type="number" className="form-control profilesettings_input" id="inputage" disabled placeholder="Enter Your Birthdate" value={age}
                    onChange={(e) => {
                      setage(e.target.value)

                    }}
                  />
                </div>

                {/* <div className="col-md-6 profilesettings_Blood_Group col-12">
                  <label for="inputbloodgroup" className="form-label profilesettings_label">Blood Group</label>
                  <select id="inputbloodgroup" className="form-select profilesettings_input" value={BP}
                    onChange={(e) => {
                      setBP(e.target.value)

                    }}
                  >
                    <option>A+</option>
                    <option>A-</option>
                    <option>B+</option>
                    <option>B-</option>
                    <option>O+</option>
                    <option>O-</option>
                    <option>AB+</option>
                    <option>AB-</option>
                  </select>
                </div> */}
                {/* </div> */}
                {/* <div className="col-md-6 profilesettings_allergies">
                    <label for="inputallergies" className="form-label profilesettings_label">Allergies</label>
                    <input type="text" className="form-control profilesettings_input" id="inputallergies" placeholder="Enter Your Allergies" value={Allergies}
                      onChange={(e) => {
                        setAllergies(e.target.value)

                      }}
                    />
                  </div> */}


                <div className="col-12 profilesettings_address">
                  <label for="inputAddress" className="form-label profilesettings_label">Address</label>
                  <textarea className="form-control profilesettings_address_textarea" placeholder="Enter Your Address" id="floatingTextarea" value={Address}
                    onChange={(e) => {
                      setAddress(e.target.value)

                    }}
                  ></textarea>
                </div>
                <div className="col-md-6 profilesettings_city">
                  <label for="inputCity" className="form-label profilesettings_label">City</label>
                  <input type="text" className="form-control profilesettings_input" placeholder="Enter City" id="inputCity" value={City}
                    onChange={(e) => {
                      setCity(e.target.value)

                    }}
                  />
                </div>
                <div className="col-md-6 profilesettings_state">
                  <label for="inputstate" className="form-label profilesettings_state_label">State</label>
                  <input type="text" className="form-control profilesettings_input" placeholder="Enter State" id="inputstate" value={State}
                    onChange={(e) => {
                      setState(e.target.value)

                    }} />
                </div>
                <div className="col-md-6 profilesettings_zip_code">
                  <label for="inputzipcode" className="form-label profilesettings_label">Zip Code</label>
                  <input type="text" className="form-control profilesettings_input" placeholder="Enter Zip Code" id="inputzipcode" value={Pincode}
                    onChange={(e) => {
                      setPincode(e.target.value)

                    }}
                  />
                </div>
                <div className="col-md-6 profilesettings_country">
                  <label for="inputcountry" className="form-label profilesettings_label">Country</label>
                  <input type="text" className="form-control profilesettings_input" placeholder="Enter Country" id="inputcountry" value={Country}
                    onChange={(e) => {
                      setCountry(e.target.value)

                    }}
                  />
                </div>
                <div className="col-12 profilesettings_other_information">
                  <label for="inputotherinformation" className="form-label profilesettings_label">Other Information</label>
                  <textarea className="form-control profilesettings_address_textarea" id="floatingTextarea" placeholder="Other Information" value={OtherInfo}
                    onChange={(e) => {
                      setOtherInfo(e.target.value)

                    }}
                  ></textarea>
                </div>
                <div className="col-12 profilesettings_savechanges_button_main">
                  <button
                    // type="submit" 
                    className="btn profilesettings_savechanges_button"
                    onClick={() => {


                      var Url = window.API_PREFIX + 'patient/add_registration_data'

                      var uploadData = new FormData();
                      uploadData.append("Token", localStorage.getItem("DToken"));
                      uploadData.append("Name", name);
                      uploadData.append("Pic", Img);
                      uploadData.append("Gender", gender);
                      uploadData.append("Weight", Weight);
                      uploadData.append("Height", height);
                      uploadData.append("Birthdate", Birthdate);
                      uploadData.append("profession", profession);

                      uploadData.append("Address", Address);
                      uploadData.append("City", City);
                      uploadData.append("State", State);
                      uploadData.append("Country", Country);
                      uploadData.append("Pincode", Pincode);
                      uploadData.append("OtherInformation", OtherInfo);
                      uploadData.append("Mobile", Phnumber);

                      fetch(Url, {
                        method: 'POST',
                        // headers: {
                        //     'Content-type': 'application/json',
                        // },
                        body: uploadData
                      }).then(resp => resp.json()).then(data => {
                        console.log(data)
                        if (data['status'] === "1") {

                          alert("Profile detail change successfully")
                          if (data['FileUpdated']) {
                            window.location.reload();
                          }

                        }
                      })

                    }}
                  >Save Changes</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* maintag-end */}

    </>
  );
}

export default PatientProfilesettings;
